import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { Link } from "react-router-dom";

import { getUserToken, getValueFromStorage } from "../../../utils/localStorage";

import { setCheckoutItems } from "../../../store/user/user";

import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import UserContacts from "./components/UserContacts/UserContacts";
import UserOrderData from "./components/UserOrderData/UserOrderData";
import WayToGet from "./components/WayToGet/WayToGet";
import PayOrder from "./components/PayOrder/PayOrder";
import { ICreateNewOrderAuthorizedUsersResponse } from "types/ICreateNewOrderAuthorizedUsers";
import { ICreateNewOrderNonAuthorizedUsersResponse } from "types/ICreateNewOrderNonAuthorizedUsers";

import styles from "./styles.module.scss";
import { DEFAULT_CITY, WAY_TO_GET } from "utils/constants";
import { IPaymentsOptions } from "types/IPaymentOptions";
import { getAvailablePaymentMethods } from "store/user/paymentTypesThunk/paymentTypesThunk";
import PaymentMethod from "pages/UserCart/Checkout/components/PaymentMethod/PaymentMethod";

const Checkout = () => {
  const { cart, checkoutData } = useAppSelector((state) => state.user);
  const [isPay, setIsPay] = useState(false);
  const [orderData, setOrderData] = useState<
    | ICreateNewOrderAuthorizedUsersResponse
    | ICreateNewOrderNonAuthorizedUsersResponse
    | null
  >(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const dispatch = useAppDispatch();

  // TODO - правильно подобрать поля provider amount city and type
  const paymentMethodsOptions = useMemo(() => {
    const options: IPaymentsOptions = {
      offers: checkoutData.items.map((item) => {
        return {
          id: item.offer.id,
          count: item.count
        };
      }),
      delivery: {
        provider: checkoutData.providerInfo?.provider ?? "parfumart",
        amount: checkoutData.providerInfo?.amount ?? 0,
        city: checkoutData.orderCity.length
          ? checkoutData.orderCity
          : DEFAULT_CITY,
        type:
          checkoutData.wayToGet === WAY_TO_GET.toDoor ? "delivery" : "pickup"
      }
    };
    return options;
  }, [
    checkoutData.items,
    checkoutData.orderCity,
    checkoutData.providerInfo?.amount,
    checkoutData.providerInfo?.provider,
    checkoutData.wayToGet
  ]);

  useEffect(() => {
    const token = getUserToken();
    if (!token) {
      return;
    }
    setIsAuthorized(true);
  }, []);

  useEffect(() => {
    if (checkoutData.items.length > 0) {
      return;
    }
    const items = getValueFromStorage("checkoutItemsIds");
    dispatch(setCheckoutItems(JSON.parse(items)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (paymentMethodsOptions.offers.length > 0) {
      dispatch(getAvailablePaymentMethods(paymentMethodsOptions));
    }
  }, [dispatch, paymentMethodsOptions]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </HelmetProvider>
      <main className={styles.root}>
        <div className={styles["title-wrapper"]}>
          <Link to="/cart" className={styles.link}>
            {"< вернуться в корзину"}
          </Link>
          <SectionTitle title="Оформление заказа" />
        </div>
        {isPay ? (
          <PayOrder orderData={orderData} />
        ) : (
          <section className={styles.data}>
            <div>
              <UserContacts />
              <WayToGet />
              <PaymentMethod />
            </div>
            <UserOrderData
              setIsPay={setIsPay}
              setOrderData={setOrderData}
              isAuthorized={isAuthorized}
            />
          </section>
        )}
      </main>
    </>
  );
};

export default Checkout;
