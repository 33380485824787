import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAvailablePaymentsMethods } from "api/paymentsApi";
import { isError } from "store/user/userThunks/userThunks";
import { AvailablePaymentMethodsResponse } from "types/IGetOrderPaymentLink";
import { IPaymentsOptions } from "types/IPaymentOptions";
import { CustomError, getErrorData, IErrorData } from "utils/getErrorData";

export const getAvailablePaymentMethods = createAsyncThunk<
AvailablePaymentMethodsResponse,
  IPaymentsOptions,
  { rejectValue: IErrorData }
>(
  "paymentTypes/getAvailablePaymentMethods",
  async (options, { rejectWithValue }) => {
    try {
      const data = await getAvailablePaymentsMethods(options);

      if (isError(data)) {
        throw new CustomError(data.message, data?.code);
      }

      return data;
    } catch (err) {
      const errorData = getErrorData(err);
      return rejectWithValue(errorData);
    }
  }
);
