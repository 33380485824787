
interface TextObject {
  text: string;
}

interface CatalogSection {
  root: TextObject;
  brands?: TextObject;
  seria?: TextObject;
  [key: string]: TextObject | undefined;
}

export interface H1TextVariables {
  "/catalog": CatalogSection;
  "/catalog/parfumeria": CatalogSection;
  "/catalog/kosmetika": CatalogSection;
}

export const h1TextVariables: H1TextVariables = {
  "/catalog": {
    root: {
      text: "Каталог Parfumart",
    },
    brands: {
      text: "{title} от бренда {brand}",
    },
    seria: {
      text: "{title} серии {seria} ",
    },
    "badges=sale": {
      text: "Распродажа",
    },
    // "kategoria=selektivnaa": {
    //   text: "Селективная парфюмерия",
    // },
    // "kategoria=elitnaa": {
    //   text: "Элитная парфюмерия",
    // },
    // "kategoria=dla-doma": {
    //   text: "Товары для дома",
    // },
  },
  "/catalog/parfumeria": {
    root: {
      text: "Парфюмерия",
    },
    brands: {
      text: "Парфюмерия от бренда {brand}",
    },
    // "kategoria=dla-doma": {
    //   text: "Товары для дома",
    // },
    "kategoria=selektivnaa": {
      text: "Селективная парфюмерия",
    },
    "kategoria=elitnaa": {
      text: "Элитная парфюмерия",
    },
    seria: {
      text: "Парфюмерия {seria}",
    },
  },
  "/catalog/kosmetika":{
    root: {
      text: "Косметика",
    },
    brands: {
      text: "Косметика от бренда {brand}",
    },
    "kategoria=dla-volos": {
      text: "Косметика для волос",
    },
    "kategoria=dla-lica": {
      text: "Косметика для лица",
    },
    "kategoria=dla-tela": {
      text: "Косметика для тела",
    },
    "kategoria=dla-makiaza": {
      text: "Косметика для макияжа",
    },
    "kategoria=dla-polosti-rta": {
      text: "Продукция для полости рта",
    },
    "kategoria=dla-solnca-i-zagara": {
      text: "Средства для солнца и загара",
    },
    "kategoria=dla-detej": {
      text: "Косметика и парфюмерия для детей",
    },
    "kategoria=dla-nogtej": {
      text: "Лаки и средства для ногтей",
    },
    "kategoria=dla-doma": {
      text: "Товары для дома",
    },
    "pol=dla-muzcin": {
      text: "Косметика для мужчин",
    },
    // "pol=dla-zensin": {
    //   text: "Косметика для женщин",
    // },
    // "pol=uniseks": {
    //   text: "Косметика унисекс",
    // },
    seria: {
      text: "Косметика {seria}",
    },
  },
};