import React, { useCallback, useMemo, useRef, useState } from "react";
import { formatPrice } from "utils/helpers/formatedPrice";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/reduxHooks";

import { IOfferGoodsTypes } from "types/IOffer";

import { MarkdownButton } from "../CartProductActions/components/MarkdownButton/MarkdownButton";
import ModalMarkDownInfo from "components/UI/ModalMarkDownInfo/ModalMarkDownInfo";
import BottomSheet from "components/UI/Bottomsheet/Bottomsheet";
import ContentMarkDownInfo from "components/UI/ContentMarkDownInfo/ContentMarkDownInfo";

import styles from "./styles.module.scss";

interface CartProductDescriptionProps {
  productName: string;
  productShortDescription: string | null;
  productArticul: string | null;
  productPricePerPiece: number;
  productLink: string;
  productOfferGoodsTypes: IOfferGoodsTypes[] | null;
}

export const CartProductDescription: React.FC<CartProductDescriptionProps> = (
  props
) => {
  const deviceType = useAppSelector((state) => state.user.deviceType);
  const [isModalMarkDown, setIsModalMarkDown] = useState(false);

  const markDownModalRef = useRef<HTMLDivElement | null>(null);

  const displayPricePerPiece = `${formatPrice(props.productPricePerPiece)} ₽ / шт`;

  const isMarkdown = useMemo(() => {
    if (
      !props.productOfferGoodsTypes ||
      (props.productOfferGoodsTypes && !props.productOfferGoodsTypes.length)
    )
      return false;
    const isMarkdown = props.productOfferGoodsTypes.filter(
      (element) => element.value === "уценка"
    );

    return !!isMarkdown[0];
  }, [props.productOfferGoodsTypes]);

  const isMarkDownInfo = useMemo(() => {
    return isModalMarkDown && !deviceType.isTablet && !deviceType.isMobile;
  }, [isModalMarkDown, deviceType]);

  const handleMarkDownInfoOpen = useCallback(() => {
    setIsModalMarkDown(true);
  }, []);

  return (
    <div className={styles["cart-product-description"]}>
      {/* ОБЩАЯ ИНФОРМАЦИЯ О ТОВАРЕ */}
      <Link to={props.productLink}>
        <p className={styles["cart-product-description__name"]}>
          {props.productName}
        </p>
      </Link>
      {props.productShortDescription && (
        <p className={styles["cart-product-description__short-description"]}>
          {props.productShortDescription}
        </p>
      )}
      {props.productArticul && (
        <p className={styles["cart-product-description__articul"]}>
          Артикул: {props.productArticul}
        </p>
      )}
      <div
        className={styles["cart-product-description__price-and-info-wrapper"]}
      >
        <p className={styles["cart-product-description__price"]}>
          {displayPricePerPiece}
        </p>
        {isMarkdown && (
          <MarkdownButton onMarkdownAction={handleMarkDownInfoOpen} />
        )}
      </div>

      {/* МОДАЛЬНОЕ ОКНО И НИЖНИЙ ЛИСТ ДЛЯ УЦЕНКИ */}
      {isMarkDownInfo && (
        <ModalMarkDownInfo
          onClose={() => setIsModalMarkDown(false)}
          modalRef={markDownModalRef}
        />
      )}
      <BottomSheet
        isOpen={isModalMarkDown && (deviceType.isTablet || deviceType.isMobile)}
        onClose={() => setIsModalMarkDown(false)}
      >
        <ContentMarkDownInfo onClose={() => setIsModalMarkDown(false)} />
      </BottomSheet>
    </div>
  );
};
