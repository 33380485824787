import React from "react";

import deleteIcon from "assets/icons/svg/trash-icon.svg";

import styles from "./styles.module.scss";

interface RemoveFromCartButtonProps {
  onRemove: () => void;
}

export const RemoveFromCartButton: React.FC<RemoveFromCartButtonProps> = ({
  onRemove
}) => {
  return (
    <button className={styles["remove-button"]} onClick={onRemove}>
      <img
        src={deleteIcon}
        alt="Удалить"
        className={styles["remove-button__image"]}
      />
    </button>
  );
};
