import { useAppSelector } from "store/reduxHooks";
import { Link } from "react-router-dom";

import PersonalAreaTab from "../../../pages/Main/components/MobileBottomNavigation/components/PersobalAreaTab/PersonalAreaTab";
import SelectCityTab from "../../../pages/Main/components/MobileBottomNavigation/components/SelectCityTab/SelectCityTab";
import boxIcon from "../../../assets/header_icons/box-icon.png";
import paymentIcon from "../../../assets/header_icons/payment-icon2.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import ScheduleTab from "pages/Main/components/MobileBottomNavigation/components/ScheduleTab/ScheduleTab";
import ContactsTab from "pages/Main/components/MobileBottomNavigation/components/ContactsTab/ContactsTab";

interface MobileNavigationAdditionalBlockProps {
  onToggleIsOpen: () => void;
}

const MobileNavigationAdditionalBlock: React.FC<MobileNavigationAdditionalBlockProps> = (props) => {
  const { currentCity, user } = useAppSelector((state) => state.user);

  return (
    <ul className={styles.list}>
      <SelectCityTab
        buttonStyles={styles.list__item}
        iconStyles={cn(styles.item__icon, styles["item__icon-geo"])}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>{currentCity ?? "Город не выбран"}</p>
      </SelectCityTab>
      <PersonalAreaTab
        buttonStyles={styles.list__item}
        iconStyles={cn(styles.item__icon, styles["item__icon-personal-aria"])}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>
          {user?.phone ? "Профиль" : "Вход/Регистрация"}
        </p>
      </PersonalAreaTab>
      <li className={styles["list__item-wrapper"]}>
        <Link to="/info/oplata" className={styles.list__item}>
          <img src={paymentIcon} alt="payment_icon" className={styles.item__icon_pay} />
          <p className={styles.item__title}>Оплата</p>
        </Link>
      </li>
      <li className={styles["list__item-wrapper"]}>
        <Link to="/info/dostavka" className={styles.list__item}>
          <img src={boxIcon} alt="box_icon" className={styles.item__icon} />
          <p className={styles.item__title}>Доставка</p>
        </Link>
      </li>
      <ScheduleTab
        buttonStyles={styles.list__item}
        iconStyles={styles.item__icon}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>График работы</p>
      </ScheduleTab>
      <ContactsTab
        buttonStyles={styles.list__item}
        iconStyles={styles.item__icon}
        cb={props.onToggleIsOpen}
      >
        <p className={styles.item__title}>Контакты</p>
      </ContactsTab>
    </ul>
  );
};

export default MobileNavigationAdditionalBlock;
