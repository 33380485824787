import { IProductImageItem } from "types/IProductImageItem";

export interface IProductImageData {
  mainProductImage: {
    id: number;
    productImage: IProductImageItem | null;
  };
  offers: IProductImageOffer[];
}

export interface IProductImageOffer {
  [id: number]: IProductImageItem;
}

// Вариант когда и оригинал добавляем в массив картинок
export const createProductImages = (
  data: IProductImageData
): IProductImageOffer[] => {
  const imagesArray: IProductImageOffer[] = [];

  const { productImage } = data.mainProductImage;
  if (productImage) {
    if (productImage.original) {
      imagesArray.push({ [data.mainProductImage.id]: productImage });
    }
  }

  if (Array.isArray(data.offers) && data.offers.length > 0) {
    imagesArray.push(...data.offers);
  }

  return imagesArray;
};
