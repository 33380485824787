import bannerImage from "../../../../assets/bestseller/small-banner.png";
import bannerDesctop from "../../../../assets/bestseller/small-banner-desctop.png";
import { useAppSelector } from "store/reduxHooks";
import styles from "./styles.module.scss";
import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";

const LandingBodyBanner: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);
  const bannerSrc = deviceType.isLargeDesktop ? bannerDesctop : bannerImage;
  return (
    <div className={styles.container}>
      <div className={styles["image-wrapper"]} itemScope itemType="https://schema.org/ImageObject">
        <link itemProp="contentUrl" href={bannerSrc} />
        <span itemProp="image">
          <SingleImageLoader
            src={bannerSrc}
            alt="Баннер преимуществ покупки в Parfumart"
            className={styles.image}
          />
        </span>
      </div>
      <div className={styles.text} itemScope itemType="https://schema.org/WebPageElement">
        <h2 className={styles.text__title} itemProp="headline">Оригинальные товары в Parfumart</h2>
        <p className={styles.text__description} itemProp="text">
          Приобретая товары в нашем интернет-магазине, вы гарантированно
          получаете только оригинальную продукцию
        </p>
      </div>
    </div>
  );
};

export default LandingBodyBanner;
