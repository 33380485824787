import * as yup from "yup";
import { phoneRegExp } from "./signUpSchema";

export interface ISchemaSignIn {
  id?: string;
  login: string;
  password: string;
}

export const signInSchema = (): yup.SchemaOf<ISchemaSignIn>  => {
  const validText = "Номер введён неправильно";

  return yup.object().shape({
    id: yup.string(),
    login: yup
      .string()
      .required("Номер телефона обязательное поле")
      .matches(phoneRegExp, validText),
    password: yup
      .string()
      .required("Пароль обязательное поле")
  });
};
