import { useState } from "react";
import { useAppSelector } from "../../../../store/reduxHooks";
import { toast } from "react-toastify";

import CustomButton from "../../../../components/CustomButton/CustomButton";

import chechMarkIcon from "../../../../assets/icons/png/chech_mark-icon.png";
import telegramIcon from "../../../../assets/icons/png/telegram-icon.png";
import vkIcon from "../../../../assets/icons/png/vk-icon.png";

import styles from "./styles.module.scss";

const SubscriptionBlock: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [email, setEmail] = useState("");

  const onClickShowCheckBox = () => {
    setShowCheckBox(!showCheckBox);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      return toast("Пожалуйста, введите ваш email для подписки.");
    }
    if (!showCheckBox) {
      return toast("Необходимо дать согласие на обработку данных.");
    }
    setEmail("");
    setShowCheckBox(false);
    toast("Вы успешно подписались на акции и распродажи!");
  };

  return (
    <div className={styles.root}>
      <form className={styles.subscribe} onSubmit={handleSubmit}>
        <h3 className={styles.subscribe_title}>
          Подпишитесь на наши акции и распродажи
        </h3>
        <input
          type="email"
          placeholder="E-mail"
          className={styles.subscribe_input}
          onChange={handleEmailChange}
          value={email}
        />
        <div className={styles.subscribe__description}>
          <div
            className={styles.subscribe__description_checkbox}
            onClick={onClickShowCheckBox}
          >
            {showCheckBox && <img src={chechMarkIcon} alt="chech_mark-icon" />}
          </div>
          <span className={styles.subscribe__description_law}>
            Я согласен на обработку данных в соответствии с ФЗ РФ от 27.07.2006,
            №152 ФЗ “О персональных данных”
          </span>
        </div>
        <CustomButton
          title="Подписаться"
          type="submit"
          className={styles.subscribe__button}
        />
      </form>
      <div className={styles.information}>
        <div className={styles.information__schedule}>
          <h4 className={styles.information__schedule_title}>График работы</h4>
          <span className={styles.information__schedule_item}>
            Пн-Пт: 9:00-19:00
          </span>
          <span className={styles.information__schedule_item}>
            Сб: 12:00-19:00
          </span>
          <span className={styles.information__schedule_item}>
            Вс: Выходной
          </span>
        </div>
        {deviceType.isMobile && (
          <div className={styles.cities}>
            <h4 className={styles.cities_title}>
              Доставка:
              <br />
              по всей территории Российской Федерации,
              <br />
              включая Республику Крым и г. Севастополь.
            </h4>
          </div>
        )}
        <div className={styles["information__social-media"]}>
          <h4 className={styles["information__social-media_title"]}>
            Мы в социальных сетях
          </h4>
          <div className={styles["information__social-media__icons"]}>
            <a
              href={`${process.env.REACT_APP_SOCIAL_NET_LINK_VK}`}
              rel="noreferrer"
              target="_blank"
              className={styles["information__social-media__icons_link"]}
            >
              <img
                src={vkIcon}
                alt="vk-icon"
                className={styles["information__social-media__icons_icon"]}
              />
            </a>
            <a
              href={`${process.env.REACT_APP_SOCIAL_NET_LINK_TLG}`}
              rel="noreferrer"
              target="_blank"
              className={styles["information__social-media__icons_link"]}
            >
              <img
                src={telegramIcon}
                alt="telegram-icon"
                className={styles["information__social-media__icons_icon"]}
              />
            </a>
          </div>
        </div>
      </div>
      {!deviceType.isMobile && (
        <div className={styles.cities}>
          <h4 className={styles.cities_title}>
            Доставка:
            <br />
            по всей территории Российской Федерации,
            <br />
            включая Республику Крым и г. Севастополь.
          </h4>
        </div>
      )}
    </div>
  );
};

export default SubscriptionBlock;
