import { useState } from "react";

import phoneIcon from "../../../../../../assets/header_icons/phone-icon.svg";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContactsInnerContent from "../../../../../../components/ContactsInnerContent/ContactsInnerContent";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ContactsTabProps {
  buttonStyles?: string;
  iconStyles?: string;
  cb?: () => void;
  children?: React.ReactNode;
}

const ContactsTab: React.FC<ContactsTabProps> = ({ buttonStyles, iconStyles, cb, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (cb) cb();
  };

  return (
    <li className={styles.root}>
      <button onClick={handleClick} className={cn(styles.tab__button, buttonStyles)}>
        <img
          src={phoneIcon}
          alt="phone_icon"
          className={cn(styles["tab__phone-icon"], iconStyles)}
        />
        {children}
      </button>
      <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ContactsInnerContent />
      </BottomSheet>
    </li>
  );
};

export default ContactsTab;
