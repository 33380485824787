import styles from "./styles.module.scss";
import cn from "classnames";

interface SkeletonLineProps {
  style?: React.CSSProperties;
  className?: string;
}

export const SkeletonLine: React.FC<SkeletonLineProps> = ({
  style,
  className
}) => {
  return (
    <>
      <div className={cn(styles.line, className)} style={style} />
    </>
  );
};
