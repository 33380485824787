import { useState } from "react";

import CartHeader from "./components/CartHeader/CartHeader";
// import CartProductItem from "./components/CartProductItem/CartProductItem";

import styles from "./styles.module.scss";
import { ICartItem } from "types/ICartItem";
import { CartProductItemV2 } from "pages/UserCart/components/CartProducts/components/CartProductItemV2/CartProductItemV2";

interface ICartProductsProps {
  products: ICartItem[];
}

const CartProducts: React.FC<ICartProductsProps> = (props) => {
  const [isModal, setIsModal] = useState(false);
  const [idDelProduct, setIdDelProduct] = useState<number | null>(null);

  return (
    <div className={styles.root}>
      <CartHeader
        isModal={isModal}
        setIsModal={setIsModal}
        idDelProduct={idDelProduct}
        setIdDelProduct={setIdDelProduct}
      />
      {!props.products.length && (
        <h3 className={styles["empty-cart"]}>
          Вы еще не добавили товары в корзину.
        </h3>
      )}
      {props.products.map((item, index) => {
        return (
          <CartProductItemV2
            setIdDelProduct={setIdDelProduct}
            setIsModal={setIsModal}
            key={`${item.offer.id}_${index}`}
            product={item}
          />
        );
      })}
    </div>
  );
};

export default CartProducts;
