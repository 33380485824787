import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  removeDeliveryAdress,
  updateUserData
} from "../../../../store/user/userThunks/userThunks";
import { personalDataSchema } from "../../../../schemas/personalDataSchema";

import AdressBlock from "./AdressBlock/AdressBlock";
import MainUserInfo from "./MainUserInfo/MainUserInfo";
import AnchorsBlock from "../components/AnchorsBlock/AnchorsBlock";

import styles from "./styles.module.scss";
import { IDeliveryAdress } from "types/IDeliveryAdressAction";
import { getErrorData } from "utils/getErrorData";

export interface IFormData {
  surname: string;
  name: string;
  phone: string;
  email: string;
  dob: string;
}

export interface IEmptyAddress {
  emptyId: string | number;
}

export interface IAnchorData {
  id: number;
  title: string;
  ref: React.RefObject<HTMLDivElement | HTMLFormElement>;
  offsetTop: number;
}

export const isEmptyAddress = (address: unknown): address is IEmptyAddress => {
  return typeof address === "object" && "emptyId" in address;
};

const PersonalData: React.FC = () => {
  const { user, deviceType } = useAppSelector((state) => state.user);

  const [localUserAdress, setLocalUserAdress] = useState<
    (IDeliveryAdress | IEmptyAddress)[] | null
      >(
      Array.isArray(user?.accountDeliveryAddresses) &&
      user.accountDeliveryAddresses.length
        ? user.accountDeliveryAddresses
        : null
      );
  const [gender, setGender] = useState(user.gender || "");
  const [showUserDataNotification, setShowUserDataNotification] =
    useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const mainUserInfoRef = useRef<HTMLFormElement | null>(null);
  const adressBlockRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const userPhoneNumber = useMemo(() => {
    if (!user.phone) {
      return "";
    }
    const phoneFirstPartNumbers = `+${user.phone[0]}(${user.phone.slice(
      1,
      4
    )})${user.phone.slice(4, 7)}`;
    const phoneLastPartNumbers = `${user.phone.slice(7, 9)}-${user.phone.slice(
      9,
      11
    )}`;
    return `${phoneFirstPartNumbers}-${phoneLastPartNumbers}`;
  }, [user.phone]);

  const anchors = useMemo(() => {
    const anchorsArray: IAnchorData[] = [
      {
        id: 0,
        title: "Основная информация",
        ref: mainUserInfoRef,
        offsetTop: 200
      },
      {
        id: 1,
        title: "Адреса доставки",
        ref: adressBlockRef,
        offsetTop: 30
      }
    ];
    return anchorsArray;
  }, []);

  const formik = useFormik<IFormData>({
    initialValues: {
      surname: user.surname || "",
      name: user.name || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || ""
    },
    validationSchema: personalDataSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const registeredUserData = await dispatch(
          updateUserData({
            surname:
              user.surname !== formik.values.surname.trim()
                ? formik.values.surname.trim()
                : undefined,
            name:
              user.name !== formik.values.name.trim()
                ? formik.values.name.trim()
                : undefined,
            // Пока что поле номера телефона нельзя редактировать 21-10-2024
            phone:
              userPhoneNumber !== formik.values.phone
                ? formik.values.phone.replace(/[^\d]/g, "")
                : undefined,
            // Пока что поле емайла нет функционала на backend для  редактирования 21-10-2024
            email:
              user.email !== formik.values.email.trim()
                ? formik.values.email.trim()
                : undefined,
            gender: !user?.gender ? gender : undefined,
            birthday: !user.birthday ? formik.values.dob : undefined
          })
        ).unwrap();

        if (!registeredUserData) {
          return;
        }

        setShowUserDataNotification(true);

        setTimeout(() => {
          setShowUserDataNotification(false);
        }, 2000);
      } catch (err) {
        const errorData = getErrorData(err);
        toast(errorData.message);
      }
    }
  });

  useEffect(() => {
    const initialValues = {
      surname: user.surname || "",
      name: user.name || "",
      phone: userPhoneNumber,
      email: user.email || "",
      dob: user.birthday || ""
    };

    const checkFormChanges = () => {
      const currentValues = {
        surname: formik.values.surname,
        name: formik.values.name,
        phone: formik.values.phone,
        email: formik.values.email,
        dob: formik.values.dob
      };

      setIsFormChanged(
        JSON.stringify(initialValues) !== JSON.stringify(currentValues)
      );
    };

    checkFormChanges();
  }, [formik.values, user, userPhoneNumber]);

  const onClickSelectGender = (gender: string) => {
    if (!user?.gender) {
      setGender(gender);
      setIsFormChanged(true);
    }
  };

  const onClickEmptyAdressAction = (type: string, idx: number) => {
    const emptyAddressObj: IEmptyAddress = {
      emptyId: "default-empty-id"
    };
    if (type === "inc") {
      setLocalUserAdress((prev) => [...prev, emptyAddressObj]);
      return;
    }

    const currentAdress = localUserAdress[idx];

    if ("id" in currentAdress) {
      dispatch(removeDeliveryAdress({ addressId: currentAdress.id }));
      if (Object.entries(localUserAdress).length === 1) {
        emptyAddressObj.emptyId = currentAdress.id;
        setLocalUserAdress([emptyAddressObj]);
      } else {
        setLocalUserAdress((prev) => prev.filter((_, index) => index !== idx));
      }
    } else {
      if (Object.entries(localUserAdress).length === 1) {
        return;
      }
      setLocalUserAdress((prev) => prev.filter((_, index) => index !== idx));
    }
  };

  const onSubmitHandler = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        Array.from(Object.values(errors)).forEach((error) => {
          toast.error(error);
        });
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <section className={styles.root}>
      <div className={styles["user-info"]}>
        <MainUserInfo
          mainUserInfoRef={mainUserInfoRef}
          formik={formik}
          gender={gender}
          disableGenderSelect={!!user?.gender}
          disabledDobSelect={!!user?.birthday}
          showUserDataNotification={showUserDataNotification}
          onClickSelectGender={onClickSelectGender}
          onSubmitHandler={onSubmitHandler}
          isFormChanged={isFormChanged}
        />
        <div className={styles.adress} ref={adressBlockRef}>
          {localUserAdress ? (
            localUserAdress.map((item, idx) => {
              if (!isEmptyAddress(item)) {
                return (
                  <AdressBlock
                    key={item.id}
                    index={idx}
                    adressAmount={localUserAdress.length - 1}
                    adressData={item}
                    onClickEmptyAdressAction={onClickEmptyAdressAction}
                    setUserAdress={setLocalUserAdress}
                  />
                );
              }
              return (
                <AdressBlock
                  key={item.emptyId}
                  index={0}
                  adressAmount={0}
                  adressData={item}
                  onClickEmptyAdressAction={onClickEmptyAdressAction}
                  setUserAdress={setLocalUserAdress}
                />
              );
            })
          ) : (
            <AdressBlock
              key="empty"
              index={0}
              adressAmount={0}
              adressData={{ emptyId: "default-empty-id" }}
              onClickEmptyAdressAction={onClickEmptyAdressAction}
              setUserAdress={setLocalUserAdress}
            />
          )}
        </div>
      </div>
      {!deviceType.isMobile && <AnchorsBlock anchors={anchors} />}
    </section>
  );
};

export default PersonalData;
