import { useMemo, useRef, useState } from "react";
import { useAppSelector } from "store/reduxHooks";
import { Link, useNavigate } from "react-router-dom";

import { getProductTitleInGenitiveCase } from "../../../utils/cartUtils";

import PersonalAreaModal from "../PersonalAreaModal/PersonalAreaModal";

import cartIcon from "../../../assets/header_icons/cart-icon.svg";
import personalAriaIcon from "../../../assets/header_icons/personal_aria-icon.svg";
import searchIcon from "../../../assets/header_icons/search-icon.svg";
import { formatPrice } from "utils/helpers/formatedPrice";

import styles from "./styles.module.scss";

const UserBlock = () => {
  const { user, cart } = useAppSelector((state) => state.user);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const price = useMemo(() => {
    return cart.reduce((acc, cur) => {
      acc += cur.calculated_amount;
      return acc;
    }, 0);
  }, [cart]);

  const productAmountTitle = useMemo(() => {
    const productAmount = cart.reduce((acc, cur) => {
      acc += cur.count;
      return acc;
    }, 0);

    return `${productAmount} ${getProductTitleInGenitiveCase(productAmount)}`;
  }, [cart]);

  const onClickHandler = () => {
    if (!user?.phone) {
      navigate("/sign-in", { replace: true });
      return;
    }
    setIsOpenModal(true);
  };

  const onClickCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className={styles.root}>
      <img src={searchIcon} alt="search-icon" className={styles["search-icon"]} />
      <button onClick={onClickHandler}>
        <img
          src={personalAriaIcon}
          alt="personal_aria-icon"
          className={styles["personal-aria-icon"]}
        />
      </button>
      <Link to="/cart" className={styles.cart}>
        <img src={cartIcon} alt="cart-icon" className={styles.cart_icon} />
        <p className={styles.cart__description}>
          <span className={styles.cart__description_price}>{`${formatPrice(price)} ₽`}</span>
          <span className={styles.cart__description_amount}>{productAmountTitle}</span>
        </p>
      </Link>
      {isOpenModal && (
        <PersonalAreaModal modalRef={modalRef} onClose={onClickCloseModal} />
      )}
    </div>
  );
};

export default UserBlock;
