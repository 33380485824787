import * as yup from "yup";

export interface ISchemaUserContacts {
  id?: string;
  name: string;
  surname?: string;
  phone: string;
  email?: string;
}

export const nameRegExp = /^[a-zA-Z, а-яёА-ЯЁ,.'-]+$/i;
// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^\+?[7 ][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;

export const userContactsSchema: yup.SchemaOf<ISchemaUserContacts> = yup
  .object()
  .shape({
    id: yup.string(),
    name: yup
      .string()
      .test(
        "not-only-spaces",
        "Имя не может состоять только из пробелов",
        (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }
      )
      .required("Укажите, пожалуйста, ваше имя"),
    surname: yup
      .string()
      .test(
        "not-only-spaces",
        "Фамилия не может состоять только из пробелов",
        (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }
      ),
    phone: yup
      .string()
      .matches(phoneRegExp, "Некорректный номер телефона")
      .required("Номер телефона обязательное поле для заполнения"),
    email: yup.string().email("Некорректный формат email")
  });
