import { NavLink } from "react-router-dom";

import cn from "classnames";
import styles from "./styles.module.scss";

interface PaymentAndDeliveryInnerContentProps {
  containerStyles?: string;
  closeModal: () => void;
}

const PaymentAndDeliveryInnerContent: React.FC<
  PaymentAndDeliveryInnerContentProps
> = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      <NavLink
        onClick={props.closeModal}
        to="/info/oplata"
        className={styles["list__item"]}
      >
        Оплата
      </NavLink>
      <NavLink
        onClick={props.closeModal}
        to="/info/dostavka"
        className={styles["list__item"]}
      >
        Доставка
      </NavLink>
    </div>
  );
};

export default PaymentAndDeliveryInnerContent;
