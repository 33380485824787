import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import getUserTokensFromRedirectQuery from "../../utils/getUserTokensFromRedirectQuery";
import { setTokens } from "../../utils/localStorage";
import { userApi } from "api/userApi";
import { useAppDispatch } from "../../store/reduxHooks";
import { setUser } from "../../store/user/user";
import { getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";

const NoIndexPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (location.pathname.includes("/login/vendor")) {
      const { token, refreshToken } = getUserTokensFromRedirectQuery(
        location.search
      );
      setTokens({
        token,
        refreshToken: refreshToken
      });

      const getUserInfo = async () => {
        try {
          const userData = await userApi.checkUser();

          if (userData.message) {
            const refreshData = await userApi.refreshUser();

            setTokens({
              token: refreshData.response.token,
              refreshToken: refreshData.response.refresh
            });

            const refreshedUserData = await userApi.checkUser();
            dispatch(setUser(refreshedUserData.response));
          } else {
            dispatch(setUser(userData.response));
          }
        } catch (error) {
          const errorData = getErrorData(error);
          // eslint-disable-next-line no-console
          console.error(
            "Ошибка при попытке авторизироваться по ссылке соц. сетей",
            errorData.message
          );
          toast.error(errorData.message);
        }
      };

      getUserInfo();
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </HelmetProvider>
  );
};

export default NoIndexPage;
