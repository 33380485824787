import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import arrow from "../../../../../assets/bestseller/arrow-bestseller.svg";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";


interface ViewAllLinkProps {
  link: string;
  isLoading?: boolean;
  isFirstLoad?: boolean;
}

const ViewAllLink: React.FC<ViewAllLinkProps> = ({ link, isLoading, isFirstLoad }) => {
  return isLoading && isFirstLoad ? (
    <SkeletonLine style={{ width: "232px", height: "64px",  borderRadius: "16px" }} />
  ) : (
    <Link to={link} className={styles.link}>
      <span>Посмотреть всё</span>
      <img src={arrow} alt="Arrow" className={styles.icon} />
    </Link>
  );
};

export default ViewAllLink;