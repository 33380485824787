import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct
} from "store/user/userThunks/userThunks";
import { toast } from "react-toastify";
import { addProductToCart } from "store/user/cartThunks/cartThunks";

import { ICartItem } from "types/ICartItem";
import { Offer } from "types/UserApiTypes";

import { FavoriteProductButton } from "../CartProductActions/components/FavoriteProductButton/FavoriteProductButton";
import { QuantitySelector } from "../CartProductActions/components/QuantitySelector/QuantitySelector";
import { RemoveFromCartButton } from "../CartProductActions/components/RemoveFromCartButton/RemoveFromCartButton";

import styles from "./styles.module.scss";
import { getUserToken } from "utils/localStorage";

interface CartProductActionsProps {
  product: ICartItem;
  setIsModal: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setIdDelProduct: (
    id: number | null | ((prevState: number | null) => number | null)
  ) => void;
}

export const CartProductActions: React.FC<CartProductActionsProps> = (
  props
) => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const isProductInFavourite = useMemo(() => {
    const idx = user.favouriteProducts.findIndex(
      (item) => item.product.id === props.product.offer.catalog.id
    );
    return idx !== -1;
  }, [user.favouriteProducts, props.product]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  const offerId = Number(props.product?.offer?.id);
  const isAvailable = props.product?.offer?.available;
  const productAmount = props.product?.count;

  const isAuthorized = !!getUserToken() && !!user?.phone.length;

  const onToggleFavoriteStatus = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.stopPropagation();
      try {
        setIsFavorite(!isFavorite);
        if (!isFavorite) {
          dispatch(addUserFavoriteProduct(props.product.offer.catalog.id));
        } else {
          dispatch(
            removeUserFavoriteProduct({
              product: props.product.offer.catalog.id
            })
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(
          "Произошла ошибка при переключении избранного товара",
          err
        );
        toast.error(
          <div>
            Произошла ошибка при переключении избранного товара.
            <br />
            ТОВАР: {`${props.product.offer.name}`}
          </div>
        );
        // Прокидываем ошибку на верхний уровень, чтоб можно было отмотать анимацию в спять
        throw new Error("Произошла ошибка при переключении избранного товара");
      }
    },
    [
      dispatch,
      isFavorite,
      props.product.offer.catalog.id,
      props.product.offer.name
    ]
  );

  const addProductAmount = useCallback(() => {
    try {
      if (!isAvailable) {
        return;
      }
      const options: Offer = {
        id: offerId,
        count: productAmount + 1
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара произошла ошибка", err);
      toast.error(`При добавлении товара c ID: ${offerId} произошла ошибка`);
    }
  }, [dispatch, isAvailable, offerId, productAmount]);

  const removeProductAmount = useCallback(() => {
    try {
      if (productAmount === 1 || !isAvailable) {
        return;
      }
      const options: Offer = {
        id: offerId,
        count: productAmount - 1
      };
      dispatch(addProductToCart([options]));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При удалении товара произошла ошибка", err);
      toast.error(`При удалении товара c ID: ${offerId} произошла ошибка`);
    }
  }, [dispatch, isAvailable, offerId, productAmount]);

  const onDeleteProduct = useCallback(() => {
    props.setIdDelProduct(offerId);
    props.setIsModal(true);
  }, [offerId, props]);

  return (
    <div className={styles["cart-product-actions"]}>
      {isAuthorized ? (
        <FavoriteProductButton
          isFavorite={isFavorite}
          onToggleFavoriteStatus={onToggleFavoriteStatus}
        />
      ) : (
        <div className={styles["cart-product-actions__empty-block"]} />
      )}
      <div
        className={styles["cart-product-actions__quantity-and-delete-wrapper"]}
      >
        <QuantitySelector
          quantity={productAmount}
          onIncreaseQuantity={addProductAmount}
          onDecreaseQuantity={removeProductAmount}
        />
        <RemoveFromCartButton onRemove={onDeleteProduct} />
      </div>
    </div>
  );
};
