import { useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { IPayment as IPayments } from "types/IOrderStatus";

interface PaymentStatusNotificationProps {
  orderNumber: number;
  date: string;
  payments: IPayments[];
}

const PaymentStatusNotification: React.FC<PaymentStatusNotificationProps> = (
  props
) => {
  const { payments, orderNumber, date } = props;

  const formattedDate = useMemo(() => {
    const dateObj = new Date(date);
    return dateObj.toLocaleString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }, [date]);

  const statusMessage = useMemo(() => {
    if (payments.some((payment) => payment.status === "wait_for_commit")) {
      return "Ожидает подтверждения";
    }
    if (
      payments.length === 0 ||
      payments.every((payment) => payment.status !== "paid")
    ) {
      return "Заказ не оплачен";
    }
    return "Оплачен";
  }, [payments]);

  return (
    <div className={styles["status-block"]}>
      <h3 className={styles["status-block__title"]}>Информация о заказе</h3>
      <p className={styles["status-block__message-block"]}>
        {orderNumber && formattedDate && (
          <span>
            Заказ № <span className={styles.primary}>{`${orderNumber}`}</span>{" "}
            от {`${formattedDate}`}
          </span>
        )}
        <span
          className={cn(styles["status-block__message-block__payment-status"], {
            [styles["status-block__message-block__payment-status--success"]]:
              statusMessage === "Оплачен",
            [styles["status-block__message-block__payment-status--fail"]]:
              statusMessage === "Заказ не оплачен",
            [styles["status-block__message-block__payment-status--pending"]]:
              statusMessage === "Ожидает подтверждения"
          })}
        >
          {statusMessage}
        </span>
      </p>
    </div>
  );
};

export default PaymentStatusNotification;
