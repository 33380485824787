import React from "react";

import LandingCategoryItem from "./components/LandingCategoryItem";
import styles from "./styles.module.scss";
import { categories } from "utils/constants/bestsellersConstants";


const LandingCategoryTabsBlock: React.FC = () => {
  return (
    <div className={styles.container} itemScope itemType="https://schema.org/ItemList">
      <meta itemProp="name" content="Категории товаров" />
      {categories.map((category, index) => (
        <div key={index} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
          <meta itemProp="position" content={`${index + 1}`} />
          <LandingCategoryItem
            title={category.title}
            image={category.image}
            link={category.link}
          />
        </div>
      ))}
    </div>
  );
};

export default LandingCategoryTabsBlock;