import React, { useEffect, useRef, useState } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

import likeAnimation from "assets/animations/likeAnimation.json";

import styles from "./styles.module.scss";

interface FavoriteProductButtonProps {
  onToggleFavoriteStatus: (
    e: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  isFavorite: boolean;
}

export const FavoriteProductButton: React.FC<FavoriteProductButtonProps> = (
  props
) => {
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setShouldPlayAnimation(true);
    try {
      await props.onToggleFavoriteStatus(e);
    } catch (error) {
      if (lottieRef.current) {
        // Поворачиваем анимацию в спять
        lottieRef.current.playSegments(
          props.isFavorite ? [181, 101] : [101, 0],
          true
        );
        // eslint-disable-next-line no-console
        console.error("Ошибка при клике на лайк", error);
      }
    }
  };

  useEffect(() => {
    if (!shouldPlayAnimation && lottieRef.current) {
      lottieRef.current.goToAndStop(props.isFavorite ? 101 : 181, true);
    }

    if (shouldPlayAnimation && lottieRef.current) {
      lottieRef.current.playSegments(
        props.isFavorite ? [0, 101] : [101, 181],
        true
      );
    }
  }, [props.isFavorite, shouldPlayAnimation]);

  return (
    <button className={styles["like-button"]} onClick={handleClick}>
      <Lottie
        lottieRef={lottieRef}
        animationData={likeAnimation}
        loop={false}
        className={styles["like-button__heart"]}
      />
    </button>
  );
};
