import React from "react";

import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";
import { useAppSelector } from "store/reduxHooks";

interface IUseCatalogPaginationOptions {
  initLimit: number;
  initOffset: number;
}

const useCatalogPagination = (options: IUseCatalogPaginationOptions) => {
  const { searchTriggered } = useAppSelector((state) => state.catalogV3);
  const [limit, setLimit] = React.useState(options.initLimit);
  const [offset, setOffset] = React.useState(options.initOffset);
  const [currentPage, setCurrentPage] = React.useState(offset / limit + 1 || 1);

  const { searchParams } = useSearchParamsHandler();

  const onResetCurrentPageAndOffset = React.useCallback(() => {
    setOffset(0);
    setCurrentPage(1);
  }, []);

  React.useEffect(() => {
    const offsetFromQuery = searchParams.get("offset");
    if (!offsetFromQuery) {
      onResetCurrentPageAndOffset();
    }
  }, [onResetCurrentPageAndOffset, searchParams, searchTriggered]);

  const onChangeLimit = React.useCallback((limit: number) => {
    setLimit(limit);
  }, []);

  const onChangeOffset = React.useCallback((offset: number) => {
    setOffset(offset);
  }, []);

  const onChangeCurrentPage = React.useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  return {
    limit,
    offset,
    currentPage,
    onChangeLimit,
    onChangeOffset,
    onChangeCurrentPage,
    onResetCurrentPageAndOffset
  };
};

export default useCatalogPagination;
