import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import useSlider from "utils/hooks/useSlider";
import DynamicalBullets from "components/UI/DynamicalBullets/DynamicalBullets";
// import bennerParfumart from "../../../../../assets/banners/bestsellers/parfumart-new.png";
// import banner1 from "../../../../../assets/banners/bestsellers/empty-perfume-bottle.png";
import { IBanner } from "utils/constants/bestsellersConstants";

interface LandingHeaderBannerProps {
  bannerItem: React.FC<{ banner: IBanner; idx: number }>;
  bannersData: IBanner[];
}

const LandingHeaderBanner: React.FC<LandingHeaderBannerProps> = ({ bannerItem: BannerItem, bannersData }) => {
  const { currentSlide, isLoaded, availableDots, sliderRef, instanceRef } =
    useSlider({
      shouldBeLooped: true,
      shouldAutoSwitched: true,
      autoSwitchInterval: 5000
    });

  const images = bannersData.map((banner: IBanner) => banner.imageUrl);
  if (images.length === 0) return null;

  if (images.length === 1) {
    const banner = bannersData[0];
    return (
      <div className={styles["single"]}>
        <div className={styles["single__slider"]} itemScope itemType="https://schema.org/ImageObject">
          <link itemProp="contentUrl" href={banner.imageUrl} />
          <link itemProp="image" href={banner.imageUrl} />
          {banner.text && <meta itemProp="description" content={banner.text} />}
          {banner.link && <link itemProp="url" href={banner.link} />}
          <BannerItem banner={banner} idx={0} />
        </div>
      </div>
    );
  }

  const handleMouseEnter = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (instanceRef.current as any)?.emit("stopped");
  };

  const handleMouseLeave = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (instanceRef.current as any)?.emit("resumed");
  };

  return (
    <div
      className={styles["slider-container"]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      itemScope
      itemType="https://schema.org/ItemList"
    >
      <meta itemProp="name" content="Баннеры Хиты продаж" />
      <div ref={sliderRef} className={cn("keen-slider", styles.slider)}>
        {bannersData.map((banner, idx) => (
          <div key={idx} itemProp="itemListElement" itemScope itemType="https://schema.org/ImageObject">
            <link itemProp="contentUrl" href={banner.imageUrl} />
            <link itemProp="image" href={banner.imageUrl} />
            {banner.text && <meta itemProp="description" content={banner.text} />}
            {banner.link && <link itemProp="url" href={banner.link} />}
            <BannerItem banner={banner} idx={idx} />
          </div>
        ))}
      </div>
      {availableDots?.length > 1 && isLoaded && instanceRef.current && (
        <div className={styles.pagination}>
          <DynamicalBullets
            availableDots={availableDots}
            currentSlide={currentSlide}
            instanceRefSlider={instanceRef}
            dotsStyles={styles["slider-dots"]}
            dotStyles={styles["slider-dot"]}
            activeColor="rgba(0, 0, 0, 0.7)"
          />
        </div>
      )}
    </div>
  );
};

export default LandingHeaderBanner;
