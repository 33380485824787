import { SHEDULE } from "../../utils/constants";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ScheduleInnerContentProps {
  containerStyles?: string;
}

const ScheduleInnerContent: React.FC<ScheduleInnerContentProps> = (props) => {
  return (
    <table className={cn(styles.schedule, props.containerStyles)}>
      <thead className={styles.schedule__head}>
        <tr className={styles.schedule__row}>
          <th className={styles["row__main-title"]}>День недели</th>
          <th className={styles["row__main-title"]}>Время работы</th>
        </tr>
      </thead>
      <tbody className={styles.schedule__body}>
        {SHEDULE.map((item, idx) => {
          return (
            <tr key={item.id} className={styles.schedule__row}>
              <td className={styles.row__subtitle}>{item.title}</td>
              <td
                className={cn(styles.row__time, {
                  [styles.row__weekend]: SHEDULE.length - 1 === idx
                })}
              >
                <time>{item.value}</time>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ScheduleInnerContent;
