import { createAsyncThunk } from "@reduxjs/toolkit";
import { isError } from "store/user/userThunks/userThunks";
import { IResponseBanners } from "types/IBannersOnMainPage";
import { CustomError, getErrorData, IErrorData, IGenericResponseRoot } from "utils/getErrorData";
import { getBanners } from "api/getBannersApi";
import { productsApi } from "api/productsApi";
import { IProfitBlockData } from "types/IProfitBlockData";

export const fetchBanners = createAsyncThunk<
  IGenericResponseRoot<IResponseBanners>,
  string,
  { rejectValue: IErrorData }
>(
  "banners/fetchBanners",
  async (folder, { rejectWithValue }) => {
    try {
      const response = await getBanners(folder);

      if (isError(response)) {
        throw new CustomError(response.message, response?.code);
      }

      return response;
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Ошибка при получении баннеров:", errorData.message);
      return rejectWithValue(errorData);
    }
  }
);

export const fetchProfitBanner = createAsyncThunk<
  IProfitBlockData,
  void,
  { rejectValue: IErrorData }
>("banners/fetchProfitBanner", async (_, { rejectWithValue }) => {
  try {
    const data = await productsApi.getDataForProfitSection();
    if (isError(data)) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }
    if (!data.response?.items) {
      throw new CustomError("Неудалось получить данные: С нами выгодно и удобно");
    }
    return data.response;
  } catch (err) {
    const errorData = getErrorData(err);
    return rejectWithValue(errorData);
  }
});