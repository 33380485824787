import { useEffect, useState } from "react";
import HeadPageData from "utils/HeadPageData";
import { IProductWithOffers } from "types/IProduct";
import { ICategoryTab } from "types/ICategoryTab";
import useLandingsProducts from "utils/hooks/useLandingsProducts";
import getLandingsProducts from "utils/helpers/getLandingsProducts";
import { parfumeriaTabIds } from "utils/constants/bestsellersConstants";
import {
  linksProfitButtonSelective,
  mensFragrancesInfo,
  mensFragrancesTabs,
  selectiveBannersData,
  selectiveQueryOptions,
  unisexFragrancesInfo,
  unisexFragrancesTabs,
  womensFragrancesInfo,
  womensFragrancesTabs
} from "utils/constants/selectiveConstants";
import { CustomError, getErrorData } from "utils/getErrorData";

import LandingHeaderBanner from "../components/LandingHeaderBanner/LandingHeaderBanner";
import SelectiveBannerItem from "./components/SelectiveBannerItem/SelectiveBannerItem";
// import LandingCategoryTabsBlock from "../components/LandingCategoryTabsBlock/LandingCategoryTabsBlock";
import LandingProfitBuyBlock from "../components/LandingProfitBuyBlock/BestsellersProfitBuyBlock";
import LandingLikeItBlock from "../components/LandingLikeItBlock/LandingLikeItBlock";
import LandingBodyBanner from "../components/LandingBodyBanner/LandingBodyBanner";
import LandingCategoryBlock, {
  ITab
} from "../components/LandingCategoryBlock/LandingCategoryBlock";

import womenLarge from "../../../assets/selective/womenDesctopNew.png";
import womenSmall from "../../../assets/selective/womenTabletNew.png";
import menLarge from "../../../assets/selective/menDesktop.png";
import menSmall from "../../../assets/selective/menTablet.png";
import uniLarge from "../../../assets/selective/uniDesktop.png";
import uniSmall from "../../../assets/selective/uniTablet.png";

import styles from "./styles.module.scss";

const Selective: React.FC = () => {
  const {
    products,
    isLoadingProducts,
    isFirstLoading,
    handleChangeShouldGetProductsStatus,
    isFirstLoad
  } = useLandingsProducts(selectiveQueryOptions);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [profitProducts, setProfitProducts] = useState<IProductWithOffers[]>(
    []
  );
  const [womensFragrancesTab, setWomensFragrancesTab] = useState<ITab[]>(
    womensFragrancesInfo.map((info) => ({
      title: info.title,
      products: [],
      link: info.link
    }) as ITab)
  );
  const [mensFragrancesTab, setMensFragrancesTab] = useState<ITab[]>(
    mensFragrancesInfo.map((info) => ({
      title: info.title,
      products: [],
      link: info.link
    }) as ITab)
  );
  const [unisexFragrancesTab, setUnisexFragrancesTab] = useState<ITab[]>(
    unisexFragrancesInfo.map((info) => ({
      title: info.title,
      products: [],
      link: info.link
    }) as ITab)
  );

  const fetchProductsForTab = async (tab: ICategoryTab) => {
    let products: IProductWithOffers[] = [];

    try {
      const { products: bestsellerProducts } = await getLandingsProducts(tab);
      products = bestsellerProducts;
    } catch (err) {
      const errorData = getErrorData(err);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return { title: tab.productType, products };
  };

  const fetchInitialTabsProducts = async () => {
    try {
      const womensPromises = womensFragrancesTabs.map((tab) =>
        fetchProductsForTab(tab)
      );
      const mensPromises = mensFragrancesTabs.map((tab) =>
        fetchProductsForTab(tab)
      );
      const unisexPromises = unisexFragrancesTabs.map((tab) =>
        fetchProductsForTab(tab)
      );

      const womensResults = await Promise.allSettled(womensPromises);
      const mensResults = await Promise.allSettled(mensPromises);
      const unisexResults = await Promise.allSettled(unisexPromises);

      const successfulWomensTabs = womensResults.reduce<ITab[]>(
        (acc, result, index) => {
          if (
            result.status === "fulfilled" &&
            result.value.products &&
            result.value.products.length > 0
          ) {
            acc.push({
              title: womensFragrancesInfo[index].title,
              products: result.value.products,
              link: womensFragrancesInfo[index].link
            });
          }
          return acc;
        },
        []
      );

      const successfulMensTabs = mensResults.reduce<ITab[]>(
        (acc, result, index) => {
          if (
            result.status === "fulfilled" &&
            result.value.products &&
            result.value.products.length > 0
          ) {
            acc.push({
              title: mensFragrancesInfo[index].title,
              products: result.value.products,
              link: mensFragrancesInfo[index].link
            });
          }
          return acc;
        },
        []
      );

      const successfulUnisexTabs = unisexResults.reduce<ITab[]>(
        (acc, result, index) => {
          if (
            result.status === "fulfilled" &&
            result.value.products &&
            result.value.products.length > 0
          ) {
            acc.push({
              title: unisexFragrancesInfo[index].title,
              products: result.value.products,
              link: unisexFragrancesInfo[index].link
            });
          }
          return acc;
        },
        []
      );

      setWomensFragrancesTab(successfulWomensTabs);
      setMensFragrancesTab(successfulMensTabs);
      setUnisexFragrancesTab(successfulUnisexTabs);
    } catch (err) {
      const errorMessage = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(errorMessage.message);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialTabsProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingProducts && products.length > 0) {
      setProfitProducts(products);
    }
  }, [products, isLoadingProducts]);

  const firstSixProducts = profitProducts.slice(0, 6);
  const secondSixProducts = profitProducts.slice(6, 12);
  const thirdSixProducts = profitProducts.slice(12, 18);

  return (
    <div className={styles.root}>
      <HeadPageData
        title="Селективная парфюмерия - Купить парфюмерию онлайн | Parfumart.ru"
        description="Селективная парфюмерия в интернет-магазине Parfumart. Большой выбор оригинальной парфюмерии c доставкой по всей России. Низкие цены, акции и скидки."
        canonical={`https://parfumart.ru${location.pathname}`}
      />
      <h1 className={styles.seo}>Селективная парфюмерия от Parfumart</h1>
      <LandingHeaderBanner
        bannerItem={SelectiveBannerItem}
        bannersData={selectiveBannersData}
      />
      {/* <LandingCategoryTabsBlock /> */}
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={firstSixProducts}
        link={linksProfitButtonSelective.firstProfitBlock}
      />
      <LandingBodyBanner />
      <LandingCategoryBlock
        title="Женские ароматы"
        imageUrl={womenSmall}
        imageUrlLargeDesktop={womenLarge}
        imagePosition="left"
        tabs={womensFragrancesTab}
        tabIds={parfumeriaTabIds}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={secondSixProducts}
        link={linksProfitButtonSelective.secondProfitBlock}
      />
      <hr className={styles.divider} />
      <LandingCategoryBlock
        title="Мужские ароматы"
        imageUrl={menSmall}
        imageUrlLargeDesktop={menLarge}
        imagePosition="right"
        tabs={mensFragrancesTab}
        tabIds={parfumeriaTabIds}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={thirdSixProducts}
        link={linksProfitButtonSelective.thirdProfitBlock}
      />
      <LandingCategoryBlock
        title="Унисекс ароматы"
        imageUrl={uniSmall}
        imageUrlLargeDesktop={uniLarge}
        imagePosition="left"
        tabs={unisexFragrancesTab}
        tabIds={parfumeriaTabIds}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <LandingLikeItBlock
        products={products}
        isLoadingProducts={isLoadingProducts}
        handleChangeShouldGetProductsStatus={
          handleChangeShouldGetProductsStatus
        }
        isFirstLoad={isFirstLoad}
      />
    </div>
  );
};

export default Selective;
