import React from "react";

import styles from "./styles.module.scss";

interface CartProductPriceProps {
  salePrice: string | null; // Пример "12 000 ₽"
  totalPrice: string; // Пример "9 000 ₽"
}

export const CartProductPrice: React.FC<CartProductPriceProps> = (props) => {
  return (
    <div className={styles["product-price"]}>
      {props.salePrice ? (
        <p className={styles["product-price__total-price"]}>
          {props.totalPrice}
        </p>
      ) : (
        <div />
      )}
      <p className={styles["product-price__sale-price"]}>
        {props.salePrice ? props.salePrice : props.totalPrice}
      </p>
    </div>
  );
};
