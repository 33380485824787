import { IProductWithOffers } from "types/IProduct";
import LandingProductCard from "../LandingProductCard/LandingProductCard";
import ViewAllLink from "./components/ViewAllLink";
import styles from "./styles.module.scss";
import { useAppSelector } from "store/reduxHooks";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { useEffect, useMemo, useState } from "react";


interface BestsellersProductsListProps {
  products: IProductWithOffers[];
  link: string;
  isLoading: boolean;
  isFirstLoading?: boolean;
}

const LandingProfitBuyBlock: React.FC<BestsellersProductsListProps> = ({ products, link, isLoading}) => {
  const { deviceType } = useAppSelector((state) => state.user);
  const [displayedProducts, setDisplayedProducts] = useState(products);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 700px) and (max-width: 809px)").matches) {
        setDisplayedProducts(products.slice(0, 4));
      } else if (window.matchMedia("(min-width: 1320px) and (max-width: 1519px)").matches) {
        setDisplayedProducts(products.slice(0, 5));
      } else if (deviceType.isTablet) {
        setDisplayedProducts(products.slice(0, 3));
      } else if (deviceType.isDesktop) {
        setDisplayedProducts(products.slice(0, 4));
      } else {
        setDisplayedProducts(products);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [deviceType, products]);

  const skeletonCount = useMemo(() => {
    if (deviceType.isLargeDesktop) return 6;
    if (deviceType.isDesktop) return 4;
    if (deviceType.isTablet) return 3;
    return 3;
  }, [deviceType]);

  return (
    <div className={styles.container} itemScope itemType="https://schema.org/ItemList">
      <meta itemProp="name" content="Покупайте с выгодой" />
      <h2 className={styles.title}>Покупайте с выгодой</h2>
      <div className={styles.products}>
        {isLoading ?  (
          Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonBestsellerCard key={index} />
          ))
        ) : (
          displayedProducts.map((product, index) => (
            <div key={product.id} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <meta itemProp="position" content={`${index + 1}`} />
              <LandingProductCard product={product} />
            </div>
          ))
        )}
      </div>
      <ViewAllLink isLoading={isLoading} link={link} />
    </div>
  );
};

export default LandingProfitBuyBlock;