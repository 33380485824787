import useShowShadow from "../../../../../../../utils/hooks/useShowShadow";

import CustomScrollbar from "../../../../../../../components/CustomScrollbar/CustomScrollbar";

import cn from "classnames";
import styles from "./styles.module.scss";
import ProductOffersListItem from "../../../../ProductMainContent/components/ProductOffersListItem/ProductOffersListItem";
import { ICurrentOffer } from "types/ICurrentOffer";
import { IOffer } from "types/IOffer";
import { IProduct } from "types/IProduct";
import { useMemo } from "react";

interface IMobileOffersListProps {
  activeOfferId: number;
  productId?: number;
  offers: IOffer[];
  onSelectOffer: (offer: ICurrentOffer) => void;
  product: IProduct | null;
}

const MobileOffersList: React.FC<IMobileOffersListProps> = (props) => {
  const { showShadow, onScrollHandler } = useShowShadow();

  // SEO AggregateOffer
  const seoPriceRange = useMemo(() => {
    const arrayOfPrice: number[] = [];
    props.offers.forEach((offer) => {
      if (offer.akcia) {
        arrayOfPrice.push(offer.akcia);
      } else if (offer.price) {
        arrayOfPrice.push(offer.price);
      } else if (offer.price_sale) {
        arrayOfPrice.push(offer.price_sale);
      }
    });

    if (!arrayOfPrice.length) {
      return null;
    }

    const priceRange = {
      highPrice: Math.max(...arrayOfPrice),
      lowPrice: Math.min(...arrayOfPrice)
    };
    return priceRange;
  }, [props.offers]);

  return (
    <CustomScrollbar
      scrollbarClassname={styles.scrollbar}
      childrenClassName={cn(
        styles.list,
        { [styles["list--single"]]: props.offers?.length === 1 },
        { [styles["list--top-shadow"]]: showShadow.top },
        { [styles["list--bottom-shadow"]]: showShadow.bottom }
      )}
      isShowScrollbar={props.offers.length > 6}
      onScrollHandler={onScrollHandler}
    >
      <div
        itemProp="offers"
        itemScope
        itemType="https://schema.org/AggregateOffer"
      >
        {props.offers.map((offer) => {
          return (
            <ProductOffersListItem
              productId={props.productId}
              key={offer.id}
              offer={offer}
              isActiveOffer={props.activeOfferId === offer.id}
              onSelectOffer={props.onSelectOffer}
            />
          );
        })}
        {!props.offers.length && (
          <p className={styles["offer--not-available"]}>Товара нет в наличии</p>
        )}
        <meta itemProp="offerCount" content={props.offers.length.toString()} />
        {!!props.product && (
          <meta itemProp="name" content={props.product?.name} />
        )}
        {seoPriceRange && (
          <>
            <meta
              itemProp="highPrice"
              content={seoPriceRange.highPrice.toString()}
            />
            <meta
              itemProp="lowPrice"
              content={seoPriceRange.lowPrice.toString()}
            />
          </>
        )}
      </div>
    </CustomScrollbar>
  );
};

export default MobileOffersList;
