import * as yup from "yup";

export interface ISchemaChangePassword {
  oldPassword: string;
  newPassword: string;
}

export const changePasswordSchema: yup.SchemaOf<ISchemaChangePassword> = yup
  .object()
  .shape({
    oldPassword: yup.string().required("Старый пароль обязательное поле"),
    newPassword: yup.string().required("Новый пароль обязательное поле")
  });
