import { useEffect, useState } from "react";
import LandingProductCard from "../LandingProductCard/LandingProductCard";
import LandingCategoryImage from "./components/LandingCategoryImage";
import { IProductWithOffers } from "types/IProduct";
import { useAppSelector } from "store/reduxHooks";
import ViewAllLink from "../LandingProfitBuyBlock/components/ViewAllLink";
import cn from "classnames";
import styles from "./styles.module.scss";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
export interface ITab {
  title: string;
  products: IProductWithOffers[];
  link: string;
}

interface BestsellersCategoryBlockProps {
  title: string;
  imageUrl: string;
  imageUrlLargeDesktop: string;
  imagePosition: "left" | "right";
  tabs: ITab[];
  tabIds: number[][];
  linkForButton?: string;
  isInitialLoading: boolean;
}

const LandingCategoryBlock: React.FC<BestsellersCategoryBlockProps> = ({
  title,
  imageUrl,
  imagePosition,
  tabs,
  tabIds,
  imageUrlLargeDesktop,
  isInitialLoading
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { deviceType } = useAppSelector((state) => state.user);
  const [numberOfSkeletons, setNumberOfSkeletons] = useState(0);

  const currentImage = deviceType.isLargeDesktop
    ? imageUrlLargeDesktop
    : imageUrl;

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const displayedProducts = window.matchMedia(
    "(min-width: 700px) and (max-width: 809px)"
  ).matches
    ? tabs[activeTab].products.slice(0, 4)
    : deviceType.isTablet
      ? tabs[activeTab].products.slice(0, 3)
      : tabs[activeTab].products;

  useEffect(() => {
    const productsCount = tabIds[activeTab]?.length || 0;
    const skeletonsCount = deviceType.isTablet ? Math.min(productsCount, 3) : productsCount;
    setNumberOfSkeletons(skeletonsCount);
  }, [tabIds, activeTab, deviceType]);

  return (
    <div
      className={cn(styles.container, {
        [styles.left]: deviceType.isLargeDesktop && imagePosition === "left",
        [styles.right]: deviceType.isLargeDesktop && imagePosition === "right"
      })}
      itemScope
      itemType="https://schema.org/ItemList"
    >
      <meta itemProp="name" content={title} />
      <LandingCategoryImage imageUrl={currentImage} />
      <div className={styles.content}>
        {isInitialLoading ? (
          <SkeletonLine
            style={{
              width: "300px",
              height: "50px",
              borderRadius: "20px",
              marginBottom: "30px"
            }}
          />
        ) : (
          <h2 className={styles.title}>{title}</h2>
        )}
        <div className={styles.tabs}>
          {isInitialLoading
            ? Array.from({ length: tabs.length }).map((_, index) => (
              <SkeletonLine
                key={index}
                style={{
                  width: "185px",
                  height: "50px",
                  borderRadius: "100px",
                  marginLeft: "20px"
                }}
              />
            ))
            : tabs.map((tab, index) => (
              <button
                key={index}
                className={cn(styles.tab, {
                  [styles.active]: activeTab === index
                })}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </button>
            ))}
        </div>
        <div className={styles.products}>
          {isInitialLoading
            ? Array.from({ length: numberOfSkeletons }).map((_, index) => (
              <SkeletonBestsellerCard key={index} />
            ))
            : displayedProducts.map((product) => (
              <LandingProductCard product={product} key={product.id}/>
            ))}
        </div>
        <div className={styles.view}>
          <ViewAllLink
            // isLoading={isLoading}
            isFirstLoad={isInitialLoading}
            link={tabs[activeTab].link}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCategoryBlock;
