import { Link } from "react-router-dom";
import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";

const ReturnAndExchange: React.FC = () => {

  const returnAndExchangeData = pageInfo.returns;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={returnAndExchangeData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница Возврат и обмен</h1>
      <SectionTitle title="Возврат и обмен" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <p className={styles.text}>
          <p>
            <span>
            Интернет-магазин
              <Link
                data-cke-saved-href="https://parfumart.ru/"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item_link}
              > Parfumart.ru
              </Link> даёт <span>100%</span> гарантию подлинности и качества
            на представленные товарный ассортимент, поэтому предлагаем возможность
            возврата или обмена любого товара, вне зависимости от причин. Вернуть
            товар можно курьеру, сразу после его получения, или в течение <span>14</span> дней
            с момента получения заказа, но только в том случае, если товар не был
            в употреблении, сохранены его потребительские свойства, товарный вид,
            оригинальная фабричная упаковка, а также предъявлены документы, подтверждающие
            факт покупки на сайте
            </span>
            <Link
              data-cke-saved-href="https://parfumart.ru/"
              to="/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item_link}
            > www.parfumart.ru
            </Link>
          </p>
        </p>
        <p className={styles.text}>
          <p>
            <span>
            Если Вы хотите вернуть заказ полностью или частично, Вам необходимо написать
            нам письмо в свободной форме и прислать его нам для ознакомления на электронную почту
            </span>
            <a
              href="https://vk.com/write?email=client@parfumart.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles["email-link"]}
            >
            client@parfumart.ru
            </a>
            <span>
            с темой письма - &quot;Возврат&quot;. Претензия будет рассмотрена в течение
              <span> 3</span> рабочих дней. Возврат денежных средств осуществляется наличным расчетом или
            на банковский счет клиента, если оплата заказа была совершена путем безналичного
            расчета, только после получения нами возвратного товара и проверки его состояния
            в течение <span>10</span> рабочих дней и в сумме, равной стоимости товаров, за исключением
            транспортных расходов на доставку и возврат товара.
            </span>
          </p>
        </p>
        <p className={styles.text}>
          <p className={styles.text_p}>
            <span>
            Если Вы нарушили упаковку товара и/или использовали купленный товар, и/или нарушили
            воздушную пробку, то возврат/обмен производится только в случае заводского брака
            (не работает распылитель, негерметичность упаковки, товарная ёмкость «течет» и др.).
            В таком случае написать нам письмо в свободной форме и прислать ее нам на
            </span>
            <a
              href="https://vk.com/write?email=client@parfumart.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles["email-link"]}
            >
            client@parfumart.ru
            </a>
            <span>
            с темой письма - &quot;Брак&quot;, желательно вместе с фотографией бракованного товара.
            Претензия будет рассмотрена в течение <span>3</span> рабочих дней.
            </span>
          </p>
        </p>
        <h3 className={styles.title}>
          Постановление Правительства РФ от 19 января 1998 г. №55
        </h3>

        <span>Перечень непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену</span>
        <span>
            на аналогичный товар других размера, формы, габарита, фасона, расцветки или
            комплектации <span className={styles["strong-text"]}>(утв. постановлением Правительства РФ от 19 января 1998 г. №55)
            (с изменениями от 20 октября 1998 г., 6 февраля 2002 г.)</span>
        </span>
        <ol className={styles.list}>
          <li>
              Товары для профилактики и лечения заболеваний в домашних условиях (предметы
              санитарии и гигиены из металла, резины, текстиля и других материалов, инструменты,
              приборы и аппаратура медицинские, средства гигиены полости рта,
              линзы очковые, предметы по уходу за детьми, лекарственные препараты);
          </li>
          <li>
              Предметы личной гигиены (зубные щётки, расчёски, заколки, бигуди для волос,
              парики, шиньоны и другие аналогичные товары);
          </li>
          <li>
              Парфюмерно-косметические товары;
          </li>
          <li>
              Текстильные товары (хлопчатобумажные, льняные, шёлковые, шерстяные и синтетические
              ткани, товары из нетканых материалов типа тканей — ленты, тесьма, кружево и другие);
              кабельная продукция (провода, шнуры, кабели); строительные и отделочные материалы
              (линолеум, плёнка, ковровые покрытия и другие) и другие товары, отпускаемые на метраж;
          </li>
          <li>
              Швейные и трикотажные изделия (изделия швейные и трикотажные бельевые, изделия чулочно-носочные);
          </li>
          <li>
              Изделия и материалы, контактирующие с пищевыми продуктами, из полимерных материалов,
              в том числе для разового использования (посуда и принадлежности столовые и кухонные,
              ёмкости и упаковочные материалы для хранения и транспортирования пищевых продуктов);
          </li>
          <li>
              Товары бытовой химии, пестициды и агрохимикаты;
          </li>
          <li>
              Мебель бытовая (мебельные гарнитуры и комплекты);
          </li>
          <li>
              Изделия из драгоценных металлов, с драгоценными камнями, из драгоценных металлов
              со вставками из полудрагоценных и синтетических камней, огранённые драгоценные камни;
          </li>
          <li>
              Автомобили и мотовелотовары, прицепы и номерные агрегаты к ним; мобильные средства
              малой механизации сельскохозяйственных работ; прогулочные суда и иные плавсредства
              бытового назначения;
          </li>
          <li>
              Технически сложные товары бытового назначения, на которые установлены гарантийные
              сроки (станки металлорежущие и деревообрабатывающие бытовые; электробытовые машины
              и приборы; бытовая радиоэлектронная аппаратура; бытовая вычислительная и множительная
              техника; фото- и киноаппаратура; телефонные аппараты и факсимильная аппаратура;
              электромузыкальные инструменты; игрушки электронные; бытовое газовое оборудование
              и устройства);
          </li>
          <li>
              Гражданское оружие, основные части гражданского и служебного огнестрельного оружия, патроны к нему;
          </li>
          <li>
              Животные и растения;
          </li>
          <li>
              Непериодические издания (книги, брошюры, альбомы, картографические и нотные издания,
              листовые изоиздания, календари, буклеты, издания, воспроизведённые на технических
              носителях информации)
          </li>
        </ol>

      </div>
    </InfoPage>
  );
};

export default ReturnAndExchange;