import arrowSvg from "../../assets/icons/svg/arrow-slider.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomArrowButtonProps {
  diraction: string;
  onClick: () => void;
  className?: string;
}

const CustomArrowButton: React.FC<CustomArrowButtonProps> = (props) => {
  return (
    <button className={props.className}>
      <img
        src={arrowSvg}
        alt="arrow-icon"
        className={cn(styles.arrow, styles[`arrow--${props.diraction}`])}
        onClick={props.onClick}
        draggable="false"
      />
    </button>
  );
};

export default CustomArrowButton;
