import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { HeadHelmet } from "../../utils";
import { productsApi } from "../../api/productsApi";
import { getCatalogPageBreadcrumbs } from "../../utils/breadcrumbsUtils";

import ProductAdditionalContent from "./Components/ProductAdditionalContent";
import MobileMainProductContent from "./Components/Mobile/MobileMainProductContent";
// import Breadcrumbs from "../../components/Breadcrumbs";
import ProductDescription from "./Components/ProductMainContent/components/ProductDescription";
import { SkeletonProductDescription } from "../../components/UI/Skeleton/SkeletonProductDescription/SkeletonProductDescription";

import styles from "./styles.module.scss";
import ProductMainContent from "./Components/ProductMainContent/ProductMainContent";
import ProductHeaderLine from "./Components/ProductHeaderLine/ProductHeaderLine";
import MobileProductHeaderLine from "./Components/Mobile/MobileMainProductContent/components/MobileProductHeaderLine/MobileProductHeaderLine";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { useAppSelector } from "store/reduxHooks";
import { IProduct } from "types/IProduct";
import { IOffer } from "types/IOffer";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { isError } from "store/user/userThunks/userThunks";
import { CustomError, getErrorData } from "utils/getErrorData";
import {
  createProductImages,
  IProductImageData,
  IProductImageOffer
} from "utils/createProductImages";
import { ICurrentOffer } from "types/ICurrentOffer";

interface IProductData {
  product: IProduct;
  offers: IOffer[];
}

const DEFAULT_OFFER: ICurrentOffer = {
  id: null,
  description: "",
  promotionPrice: null,
  price: null,
  percent: null,
  available: null
};

const SingleProductPage: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);
  const { productId } = useParams<{ productId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [productData, setProductData] = useState<IProductData | null>(null);
  const [productImages, setProductImages] = useState<IProductImageOffer[]>([]);
  const [currentOffer, setCurrentOffer] =
    useState<ICurrentOffer>(DEFAULT_OFFER);
  const [isLoading, setIsLoading] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const reviewsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const id = parseInt(productId.split("-", 1)[0], 10);
        const productNameFromQuery = productId.split(`${id}-`)[1];

        const data = await productsApi.getProductById(id);

        if (isError(data)) {
          throw new CustomError(data.message, data?.code);
        }

        if (
          data.response &&
          productNameFromQuery !== data.response.product.product_rewrite_name
        ) {
          return location.replace(
            `/product/${data.response.product.id}-${data.response.product.product_rewrite_name}`
          );
        }

        if (data.response) {
          setProductData(data.response);

          const productImageData: IProductImageData = {
            mainProductImage: {
              id: data.response.product.id,
              productImage: data.response.product.productImage
            },
            offers: data.response.offers
              .map((offerItem) => {
                const productOfferImage: IProductImageOffer = {
                  [offerItem.id]: offerItem.offerImage
                };
                return productOfferImage;
              })
              .filter(
                (offerImage): offerImage is IProductImageOffer =>
                  Object.values(offerImage)[0] !== null
              )
          };

          const productImages = createProductImages(productImageData);

          if (productImages.length) {
            setProductImages(productImages);
            // Логика поиска изначального активного индекса по параметру type если таков есть
            const initialActiveImageIndex = () => {
              const typeSearchParams = searchParams.get("type");

              if (typeSearchParams) {
                const initialIndex = productImages.findIndex(
                  (productImage) =>
                    Object.keys(productImage)[0] === typeSearchParams
                );
                if (initialIndex !== -1) {
                  return setActiveImageIndex(initialIndex);
                }

                return setActiveImageIndex(0);
              }
              return setActiveImageIndex(0);
            };
            initialActiveImageIndex();
          }
        }
      } catch (error) {
        const errorMessage = getErrorData(error);
        // eslint-disable-next-line no-console
        console.error(errorMessage.message);
        return location.replace("/");
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbsValues = useMemo(() => {
    if (!productData?.product) {
      return [];
    }
    return getCatalogPageBreadcrumbs(productData.product);
  }, [productData]);

  const productMinDescription = useMemo(() => {
    const productItemWithVolume = productData?.offers?.find((item) => {
      if (!item.offerVolumes) return "";
      return item.offerVolumes.find((elem) => {
        return elem.value_type;
      });
    });

    if (!productItemWithVolume) return "";

    const productVolume = productItemWithVolume.offerVolumes[0].value_type;

    const description = productData.offers
      .sort((a, b) => a.name.length - b.name.length)[0]
      .name.split(`${productVolume} `)[1];

    if (!description) return "";

    return description[0].toUpperCase() + description.slice(1);
  }, [productData?.offers]);

  const scrollToReviews = () => {
    reviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const metaData = useMemo(() => {
    if (!productData || !productData.product) {
      return {
        title:
          "купить с доставкой по всей территории России | Интернет-магазин Parfumart",
        description:
          "купить в Интернет-магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России"
      };
    }

    const name = productData?.product?.name;
    const offerName = productData?.offers?.[0]?.name ?? "";
    const typeWithoutMl = offerName.replace(/\d+\sмл/, "").trim();
    const type = typeWithoutMl.split(",")[0];
    const baseTitle =
      "купить с доставкой по всей территории России | Интернет-магазин Parfumart";
    const baseDescription =
      "купить в Интернет-магазине Parfumart ☛ Низкие цены ☛ Большой выбор ☛ Доставка по всей территории России";

    const isCosmetic = productData?.product?.category.name === "Косметика";
    const isPerfume = productData?.product?.category.name === "Парфюмерия";

    let title;
    let metaDescription;

    if (isCosmetic) {
      title = `${name} - ${baseTitle}`;
      metaDescription = `${name} - ${baseDescription}`;
    } else if (isPerfume) {
      title = `${name} ${type} - ${baseTitle}`;
      metaDescription = `${name} ${type} - ${baseDescription}`;
    } else {
      title = `${name} - ${baseTitle}`;
      metaDescription = `${name} - ${baseDescription}`;
    }

    return { title, description: metaDescription, name, type };
  }, [productData]);

  // yandex ecommerce 'detail'
  useEffect(() => {
    if (!productData) {
      return;
    }

    const product = productData.product;
    const detailData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.detail]: {
          products: [
            {
              id: product.id?.toString(),
              name: product.name,
              category: product.category.name,
              brand:
                product.brands && !!product.brands.length
                  ? product.brands[0].brand.name
                  : ""
            }
          ]
        }
      }
    };

    const handleEcommerceData = async () => {
      await handleYandexEcommerce(detailData);
    };

    handleEcommerceData();
  }, [productData]);

  const handleChangeCurrentOffer = useCallback((offer: ICurrentOffer) => {
    setCurrentOffer(offer);
  }, []);

  const handleSelectOffer = useCallback(
    (options: ICurrentOffer) => {
      handleChangeCurrentOffer(options);
      setSearchParams({ type: options.id.toString() });

      // Логика выбора фотографии по офферу
      const offerImage = productImages.find(
        (offerImage) => offerImage[options.id]
      );
      if (offerImage) {
        const newActiveIndex = productImages.findIndex(
          (productImage) =>
            Object.keys(productImage)[0] === options.id?.toString()
        );

        if (newActiveIndex !== -1) {
          setActiveImageIndex(newActiveIndex);
        }
      }
    },
    [handleChangeCurrentOffer, productImages, setSearchParams]
  );

  return (
    <>
      <HeadHelmet title={metaData.title} description={metaData.description} />
      <main
        className={styles.root}
        itemScope
        itemType="https://schema.org/Product"
      >
        {!!productData?.product.productImage && (
          <meta
            itemProp="image"
            content={productData.product.productImage.original.link}
          />
        )}
        {!!productData?.product.name && (
          <meta itemProp="name" content={productData.product.name} />
        )}
        {!deviceType.isMobile && !deviceType.isTablet ? (
          <div>
            <ProductHeaderLine
              productId={productData?.product.id}
              breadcrumbsValues={breadcrumbsValues}
              image={productData?.product?.productImage?.original?.link}
              productTitle={productData?.product.name}
              isLoading={isLoading}
            />
            <ProductMainContent
              product={productData?.product}
              offers={productData?.offers}
              properties={productData?.product.properties}
              scrollToReviews={scrollToReviews}
              article={productData?.product.vendorCode}
              isLoading={isLoading}
              type={metaData.type}
              name={metaData.name}
              productImages={productImages}
              onChangeCurrentOffer={handleChangeCurrentOffer}
              currentOffer={currentOffer}
              onSelectOffer={handleSelectOffer}
              activeImageIndex={activeImageIndex}
              setActiveImageIndex={setActiveImageIndex}
            />
            {process.env.REACT_APP_COMPONENT_ENABLE_QandA_FOR_OFFER !==
              "false" && (
              <ProductAdditionalContent
                title={productData?.product.name}
                description={productMinDescription}
                reviewsRef={reviewsRef}
              />
            )}
          </div>
        ) : (
          <>
            {/* <Breadcrumbs
              breadcrumbs={breadcrumbsValues}
              containerStyles={styles.breadcrumbs}
              isLoading={isLoading}
              showArrow
              isLastItemArrowRotated
            /> */}
            <MobileProductHeaderLine
              productId={productData?.product.id}
              breadcrumbsValues={breadcrumbsValues}
              image={productData?.product?.productImage?.original?.link}
              productTitle={productData?.product.name}
              isLoading={isLoading}
            />
            <MobileMainProductContent
              product={productData?.product}
              offers={productData?.offers}
              isLoading={isLoading}
              productImages={productImages}
              onChangeCurrentOffer={handleChangeCurrentOffer}
              currentOffer={currentOffer}
              onSelectOffer={handleSelectOffer}
              activeImageIndex={activeImageIndex}
              setActiveImageIndex={setActiveImageIndex}
            />
            {isLoading ? (
              <SkeletonProductDescription />
            ) : (
              <ProductDescription
                brandTitle={productData?.product.brands[0].brand.name}
                brandRewriteName={
                  productData?.product.brands[0].brand.rewrite_name
                }
                productTitle={productData?.product.name}
                productRewrateName={productData?.product.product_rewrite_name}
                category={productData?.product.category}
                description={productData?.product.description}
                properties={productData?.product.properties}
                offers={productData?.offers}
                scrollToReviews={scrollToReviews}
              />
            )}
          </>
        )}
      </main>
    </>
  );
};

export default SingleProductPage;
