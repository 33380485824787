import { useEffect, useRef, useState } from "react";

import CustomSlider from "../../../../components/CustomSlider/CustomSlider";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";

import styles from "./styles.module.scss";
import { IProductWithOffers } from "types/IProduct";

interface SliderSettings {
  infinite?: boolean;
  dots?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  lazyLoad?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  pauseOnHover?: boolean;
  arrows?: boolean;
}

const sliderSettings: SliderSettings = {
  infinite: true,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: false,
};

const LastProductsViewBlock = () => {
  const [products, setProducts] = useState<IProductWithOffers[]>([]);
  const sliderRef = useRef(null); // что бы не тянуть типы для slick-slider пока не типизировал

  const onClickSlickPrev = () => {
    sliderRef?.current?.slickPrev();
  };

  const onClickSlickNext = () => {
    sliderRef?.current?.slickNext();
  };

  useEffect(() => {
    const productsFromStorage = localStorage.getItem("lastProductsView");
    if (!productsFromStorage) return;
    // TODO: тут должна быть логика для получения продуктов в локальном хранилище
    try {
      const parsedProducts: IProductWithOffers[] = JSON.parse(productsFromStorage);
      setProducts(parsedProducts);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Ошибка при парсинге продуктов из локального хранилища", error);
    }
  }, []);

  return (
    !!products.length &&
      <div className={styles.root}>
        <SectionTitle title='НЕДАВНО ПРОСМОТРЕННЫЕ' />
        <CustomSlider
          products={products}
          sliderRef={sliderRef}
          onClickSlickPrev={onClickSlickPrev}
          onClickSlickNext={onClickSlickNext}
          settings={sliderSettings}
        >
          {
            products.map((product) => (
              <ProductCard product={product} key={product.id} />
            ))
          }
        </CustomSlider>
      </div>
  );
};

export default LastProductsViewBlock;