import { useState } from "react";

import sheduleIcon from "../../../../../../assets/header_icons/schedule-icon.svg";

import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import ScheduleInnerContent from "../../../../../../components/ScheduleInnerContent/ScheduleInnerContent";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ScheduleTabProps {
  buttonStyles?: string;
  iconStyles?: string;
  cb?: () => void;
  children?: React.ReactNode;
}

const ScheduleTab: React.FC<ScheduleTabProps> = ({ buttonStyles, iconStyles, cb, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (cb) cb();
  };

  return (
    <li className={styles.root}>
      <button onClick={handleClick} className={cn(styles.tab__button, buttonStyles)}>
        <img
          src={sheduleIcon}
          alt="schedule_icon"
          className={cn(styles["tab__schedule-icon"], iconStyles)}
        />
        {children}
      </button>
      <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ScheduleInnerContent />
      </BottomSheet>
    </li>
  );
};

export default ScheduleTab;
