import { useEffect, useMemo, useRef, useState } from "react";
import ReactSlider from "react-slider";
import { IFilterCommon, IPricesObject } from "types/IFiltersV3";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { formatPrice } from "utils/helpers/formatedPrice";
import { FilterKeysEnum } from "types/FilterKeysEnum";

import {
  ISelectedFilter,
  setPriceFilter,
  toggleSearchTrigger
} from "store/catalogV3";
import { FiltersLocalization } from "utils/constants/filters";

import styles from "./styles.module.scss";

interface IPriceMultiRangeSliderV3Props {
  isMobileDevice?: boolean;
}

const INPUT_PRICE_CHANGE_DELAY = 2000; // 2 секунды
const MIN_STEP = 50;

const PriceMultiRangeSliderV3: React.FC<IPriceMultiRangeSliderV3Props> = ({
  isMobileDevice
}) => {
  const { filtersV3, selectedFilters } = useAppSelector(
    (state) => state.catalogV3
  );
  const deviceType = useAppSelector((state) => state.user.deviceType);
  const maxProductsPerPage = deviceType.isMobile ? 10 : 18;
  const [searchParams, setSearchParams] = useSearchParams();

  const priceFilter = useMemo(() => {
    if (filtersV3.prices) {
      return {
        ...filtersV3.prices
      };
    }

    return null;
  }, [filtersV3]);

  const selectedPriceFilter = selectedFilters.find(
    (filter) => filter.mainFilterRewriteName === FilterKeysEnum.PRICES
  );
  const dispatch = useAppDispatch();

  const currentSelectedMinPrice =
    selectedPriceFilter?.filters &&
    selectedPriceFilter.filters.find(
      (subFilter) => (subFilter as IFilterCommon).rewrite_name === "min_price"
    );
  const currentSelectedMaxPrice =
    selectedPriceFilter?.filters &&
    selectedPriceFilter.filters.find(
      (subFilter) => (subFilter as IFilterCommon).rewrite_name === "max_price"
    );

  const initialMinPrice =
    (currentSelectedMinPrice as IFilterCommon)?.name ??
    priceFilter?.min.toString() ??
    "0";
  const initialMaxPrice =
    (currentSelectedMaxPrice as IFilterCommon)?.name ??
    priceFilter?.max.toString() ??
    "0";

  const [priceRange, setPriceRange] = useState<IPricesObject>({
    // eslint-disable-next-line camelcase
    min_price: priceFilter?.min
      ? priceFilter?.min.toString()
      : ((currentSelectedMinPrice as IFilterCommon)?.name ?? "0"),
    // eslint-disable-next-line camelcase
    max_price: priceFilter?.max
      ? priceFilter?.max.toString()
      : ((currentSelectedMaxPrice as IFilterCommon)?.name ?? "0")
  });
  const [tempMinPrice, setTempMinPrice] = useState<number>(
    Number(initialMinPrice)
  );
  const [tempMaxPrice, setTempMaxPrice] = useState<number>(
    Number(initialMaxPrice)
  );
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  // для того чтоб не конфликтовали onChange с таймером и onBlur - введена данная переменная didPriceUpdate
  const didPriceUpdate = useRef(false);

  useEffect(() => {
    setTempMinPrice(Number(initialMinPrice));
    setTempMaxPrice(Number(initialMaxPrice));
  }, [initialMaxPrice, initialMinPrice]);

  useEffect(() => {
    setPriceRange({
      // eslint-disable-next-line camelcase
      min_price: priceFilter.min.toString(),
      // eslint-disable-next-line camelcase
      max_price: priceFilter.max.toString()
    });
  }, [priceFilter.max, priceFilter.min]);

  const handleSliderChange = (value: number[]) => {
    const [minValue, maxValue] = value;
    setTempMinPrice(minValue);
    setTempMaxPrice(maxValue);
  };

  const handleSelectPrice = (value: number[]) => {
    const [currentMinValue, currentMaxValue] = value;

    const searchParamsObject: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      searchParamsObject[key] = value;
    });

    const updatedSearchParamsWithPrice: Record<string, string> = {
      ...searchParamsObject,
      // eslint-disable-next-line camelcase
      min_price: currentMinValue.toString(),
      // eslint-disable-next-line camelcase
      max_price: currentMaxValue.toString(),
      limit: maxProductsPerPage.toString(),
      offset: "0"
    };

    const selectedPriceFilter: ISelectedFilter = {
      mainFilterRewriteName: FilterKeysEnum.PRICES,
      mainFilterLocalizedName: FiltersLocalization[FilterKeysEnum.PRICES],
      filters: [
        {
          name: currentMinValue.toString(),
          // eslint-disable-next-line camelcase
          rewrite_name: "min_price"
        },
        {
          name: currentMaxValue.toString(),
          // eslint-disable-next-line camelcase
          rewrite_name: "max_price"
        }
      ]
    };

    dispatch(setPriceFilter(selectedPriceFilter));

    if (!isMobileDevice) {
      setSearchParams(new URLSearchParams(updatedSearchParamsWithPrice));
    } else {
      dispatch(toggleSearchTrigger());
    }
  };

  // главная функция применяющая проверки больше меньше и изменяющая параметры поиска цены для инпута
  const handleApplyInputPriceChanges = (
    price: string,
    inputType: "min" | "max"
  ) => {
    const currentPrice = price;
    const minAllowedPrice = priceRange.min_price;
    const maxAllowedPrice = priceRange.max_price;
    const searchParamsObject: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      searchParamsObject[key] = value;
    });

    const updateSearchParams = (minPrice: number, maxPrice: number) => {
      const updatedSearchParamsWithPrice: Record<string, string> = {
        ...searchParamsObject,
        // eslint-disable-next-line camelcase
        min_price: minPrice.toString(),
        // eslint-disable-next-line camelcase
        max_price: maxPrice.toString(),
        limit: maxProductsPerPage.toString(),
        offset: "0"
      };

      const selectedPriceFilter: ISelectedFilter = {
        mainFilterRewriteName: FilterKeysEnum.PRICES,
        mainFilterLocalizedName: FiltersLocalization[FilterKeysEnum.PRICES],
        filters: [
          // eslint-disable-next-line camelcase
          { name: minPrice.toString(), rewrite_name: "min_price" },
          // eslint-disable-next-line camelcase
          { name: maxPrice.toString(), rewrite_name: "max_price" }
        ]
      };

      dispatch(setPriceFilter(selectedPriceFilter));

      didPriceUpdate.current = true;
      if (!isMobileDevice) {
        setSearchParams(new URLSearchParams(updatedSearchParamsWithPrice));
      } else {
        dispatch(toggleSearchTrigger());
      }
    };

    if (inputType === "min") {
      const adjustedMinPrice = Math.min(
        Math.max(Number(currentPrice), Number(minAllowedPrice)),
        tempMaxPrice - 50
      );
      setTempMinPrice(adjustedMinPrice);
      updateSearchParams(adjustedMinPrice, tempMaxPrice);
      return;
    }

    const adjustedMaxPrice = Math.max(
      Math.min(Number(currentPrice), Number(maxAllowedPrice)),
      tempMinPrice + 50
    );
    setTempMaxPrice(adjustedMaxPrice);
    updateSearchParams(tempMinPrice, adjustedMaxPrice);
  };

  const handleInputPriceValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputType: "min" | "max"
  ) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    didPriceUpdate.current = false;
    if (inputType === "min") {
      setTempMinPrice(Number(numericValue));
    } else {
      setTempMaxPrice(Number(numericValue));
    }

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      if (!didPriceUpdate.current) {
        if (inputType === "min") {
          handleApplyInputPriceChanges(numericValue, "min");
        } else {
          handleApplyInputPriceChanges(numericValue, "max");
        }
      }
    }, INPUT_PRICE_CHANGE_DELAY);

    setDebounceTimer(timer);
  };

  const handleInputBlurOrEnter = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
    inputType: "min" | "max"
  ) => {
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return;
    }

    const value = inputType === "min" ? tempMinPrice : tempMaxPrice;
    const isMinInputValueChanged =
      inputType === "min" && value !== Number(initialMinPrice);
    const isMaxInputValueChanged =
      inputType === "max" && value !== Number(initialMaxPrice);

    if (!didPriceUpdate.current) {
      if (inputType === "min" && isMinInputValueChanged) {
        handleApplyInputPriceChanges(value.toString(), inputType);
      } else if (inputType === "max" && isMaxInputValueChanged) {
        handleApplyInputPriceChanges(value.toString(), inputType);
      }
    }
  };

  const sliderMinDistenceStep = useMemo(() => {
    if (!priceRange.max_price && !priceRange.min_price) {
      return 1;
    }

    // Если разница цены меньше минимального заданого шага, то делаем шаг равным 1
    const isMinStepApplieble =
      Number(priceRange.max_price) - Number(priceRange.min_price) > MIN_STEP;

    if (isMinStepApplieble) {
      return MIN_STEP;
    }

    return 1;
  }, [priceRange.max_price, priceRange.min_price]);

  return (
    <div className={styles.root}>
      <div className={styles["price-content"]}>
        <div className={styles["price-content__min-price"]}>
          <span className={styles["price-content__min-price_text"]}>От</span>
          <input
            type="text"
            value={formatPrice(tempMinPrice)}
            className={styles["price-content__min-price_input"]}
            onChange={(e) => handleInputPriceValueChange(e, "min")}
            onBlur={(e) => handleInputBlurOrEnter(e, "min")}
            onKeyDown={(e) => handleInputBlurOrEnter(e, "min")}
          />
        </div>
        <div className={styles["price-content__max-price"]}>
          <span className={styles["price-content__min-price_text"]}>До</span>
          <input
            type="text"
            value={formatPrice(tempMaxPrice)}
            className={styles["price-content__max-price_input"]}
            onChange={(e) => handleInputPriceValueChange(e, "max")}
            onBlur={(e) => handleInputBlurOrEnter(e, "max")}
            onKeyDown={(e) => handleInputBlurOrEnter(e, "max")}
          />
        </div>
      </div>
      <ReactSlider
        className={styles.slider}
        thumbClassName={styles.slider_thumb}
        trackClassName={styles.slider_track}
        min={Number(priceRange.min_price)}
        max={Number(priceRange.max_price)}
        value={[tempMinPrice, tempMaxPrice]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        pearling
        minDistance={sliderMinDistenceStep}
        onChange={handleSliderChange}
        onAfterChange={handleSelectPrice}
      />
      <div className={styles["price-range"]}>
        <p>{formatPrice(priceRange.min_price)} ₽</p>
        <p>{formatPrice(priceRange.max_price)} ₽</p>
      </div>
    </div>
  );
};

export default PriceMultiRangeSliderV3;
