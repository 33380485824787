import { Link } from "react-router-dom";
import {
  COMPANY_LINKS,
  COSMETIC_LINKS,
  INFORMATION_LINKS,
  PARFUME_LINKS,
} from "../../utils/constants/footerLinks";

import ListWithLinks from "./components/ListWithLinks/ListWithLinks";

import parfumartLogo from "../../assets/parfumart-logo.svg";
import SubscriptionBlock from "./components/SubscriptionBlock/SubscriptionBlock";

import styles from "./styles.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={styles.root}>

      <span itemScope itemType="https://schema.org/Organization" >
        <meta itemProp="name" content="Parfumart.ru - Интернет-магазин парфюмерии и косметики." />
        <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress" >
          <meta itemProp="streetAddress" content="Меланжевая, 10. Деловой центр AVM-ORSETTO, 1 этаж, офис 102." />
          <meta itemProp="addressLocality" content="Россия, Краснодар" />
          <meta itemProp="telephone" content="8 (861) 290-21-10" />
          <meta itemProp="telephone" content="8 (918) 977-21-10" />
          <meta itemProp="email" content="client@parfumart.ru" />
          <meta itemProp="email" content="ceo@parfumart.ru" />
        </span>
      </span>

      <div className={styles["blocks-wrapper"]}>
        <div className={styles["lists-wrapper"]}>
          <Link to="/">
            <img
              src={parfumartLogo}
              alt="parfumart_logo"
              className={styles["main-logo"]}
            />
          </Link>
          <div className={styles["links"]}>
            <div className={styles["links__products"]}>
              <ListWithLinks title="ПАРФЮМЕРИЯ" list={PARFUME_LINKS} />
              <ListWithLinks title="КОСМЕТИКА" list={COSMETIC_LINKS} />
            </div>
            <ListWithLinks title="КОМПАНИЯ" list={COMPANY_LINKS} />
            <div className={styles.info}><ListWithLinks title="ИНФОРМАЦИЯ" list={INFORMATION_LINKS} /></div>
          </div>
        </div>
        <div>
          <SubscriptionBlock />
          <div className={styles["info-mobile"]}><ListWithLinks title="ИНФОРМАЦИЯ" list={INFORMATION_LINKS} /></div>
        </div>
      </div>
      <div className={styles["сopyright-block_wrapper"]}>
        <div className={styles["сopyright-block"]}>
          <p className={styles["сopyright-block__description"]}>
            <span>
              <span className={styles["сopyright-block_symbol"]}>&copy;</span>
              <span>2012-{new Date().getFullYear()} Parfumart.ru - Интернет-магазин парфюмерии и косметики.</span>
            </span>
            <span>
              Только оригинальная, сертифицированная продукция. Доставка по всей
              территории России. Все права защищены.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
