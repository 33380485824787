import { productsApi } from "api/productsApi";
import { getQueryStringForRequest } from "./catalogPageHelper";
import { CustomError, getErrorData, REJECT_ERROR_CODE } from "utils/getErrorData";
import { ICategoryTab } from "types/ICategoryTab";
import { IProductWithOffers } from "types/IProduct";
import { isError } from "store/user/userThunks/userThunks";

const getLandingsProducts = async (tab: ICategoryTab) => {
  const abortController = new AbortController();
  let isLoading = true;
  let products: IProductWithOffers[] = [];

  try {
    const requestString = getQueryStringForRequest({
      categories: tab.category,
      product_types: tab.productType,
      limit: "4",
      offset: "0",
      ...tab.additionalQuery
    });

    const data = await productsApi.getCatalogWithSearchOptions({
      requestString,
      signal: abortController.signal,
    });

    if (isError(data)) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    products = data.response.items;
  } catch (err) {
    if (err instanceof Error && err.name !== "AbortError") {
      // eslint-disable-next-line no-console
      throw new CustomError("При попытке показать товары, произошла ошибка", REJECT_ERROR_CODE);
    }
    const errorData = getErrorData(err);
    throw new CustomError(errorData.message, errorData?.code);
  } finally {
    isLoading = false;
  }

  return { products, isLoading, abortController };
};

export default getLandingsProducts;