import React, { useState, useEffect, memo } from "react";
import cn from "classnames";

import { IProductImageItem } from "types/IProductImageItem";
import { IImagesCache } from "types/IImagesCache";

import styles from "./styles.module.scss";

interface ProductProgressiveImageProps extends IProductImageItem {
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  customStyles?: string;
  imagesCache: IImagesCache;
}

// Не забываем прокидывать кэш картинок  - ВАЖНО для производительности

type ImageState =
  | "default"
  | "first-image"
  | "second-image"
  | "third-image"
  | "error";

export const ProductProgressiveImage: React.FC<ProductProgressiveImageProps> =
  memo(
    ({
      thumbnail_q_0,
      thumbnail_q_50,
      thumbnail_q_100,
      original,
      imgProps,
      imagesCache,
      customStyles
    }) => {
      const [currentSrc, setCurrentSrc] = useState<string | null>(null);
      const [currentImgState, setCurrentImgState] =
        useState<ImageState>("default");
      const [hasError, setHasError] = useState(false);
      const [isMounted, setIsMounted] = useState(true);

      useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        setIsMounted(true);

        const loadImages = async () => {
          if (hasError) return;

          const loadImage = (src: string): Promise<string> => {
            if (imagesCache[src]) {
              return Promise.resolve(imagesCache[src]);
            }

            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = () => {
                imagesCache[src] = src;
                resolve(src);
              };
              img.onerror = (error) => {
                setHasError(true);
                setCurrentImgState("error");
                // eslint-disable-next-line no-console
                console.error(`Не удалось загрузить картинку ${src}`, error);
                reject(error);
              };

              signal.addEventListener("abort", () => {
                reject(new DOMException("Aborted", "AbortError"));
              });
            });
          };

          try {
            if (thumbnail_q_0) {
              const src = await loadImage(thumbnail_q_0.link);
              if (isMounted) {
                setCurrentSrc(src);
                setCurrentImgState("first-image");
              }
            }

            if (thumbnail_q_50) {
              const src = await loadImage(thumbnail_q_50.link);
              if (isMounted) {
                setCurrentSrc(src);
                setCurrentImgState("second-image");
              }
            }

            if (thumbnail_q_100) {
              const src = await loadImage(thumbnail_q_100.link);
              if (isMounted) {
                setCurrentSrc(src);
                setCurrentImgState("third-image");
              }
            } else if (original) {
              const src = await loadImage(original.link);
              if (isMounted) {
                setCurrentSrc(src);
                setCurrentImgState("third-image");
              }
            }
          } catch (error) {
            if (
              typeof error === "object" &&
              "name" in error &&
              error.name === "AbortError"
            ) {
              // eslint-disable-next-line no-console
              console.log(
                "\x1b[33m%s\x1b[0m",
                "Произошла отмена загрузки фотографии" + error
              );
            } else {
              // eslint-disable-next-line no-console
              console.error(
                `Ошибка при загрузке картинки ${original.link}`,
                error
              );
            }
          }
        };

        loadImages();

        return () => {
          controller.abort();
          setIsMounted(false);
          setCurrentSrc(null);
          setHasError(false);
          setCurrentImgState("default");
        };
      }, [
        hasError,
        isMounted,
        imagesCache,
        thumbnail_q_0,
        thumbnail_q_50,
        thumbnail_q_100,
        original
      ]);

      if (hasError) {
        return null;
      }

      return (
        <img
          {...imgProps}
          className={cn(
            styles.image,
            {
              [styles["image--first-transition"]]:
                currentImgState === "first-image",
              [styles["image--second-transition"]]:
                currentImgState === "second-image",
              [styles["image--third-transition"]]:
                currentImgState === "third-image"
            },
            customStyles
          )}
          src={currentSrc || ""}
          alt={imgProps?.alt || ""}
        />
      );
    }
  );

ProductProgressiveImage.displayName = "ProductProgressiveImage";
