import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";

import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import { IImagesCache } from "types/IImagesCache";
import { ProductModalImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductModalImage/ProductModalImage";
import { IProductImageOffer } from "utils/createProductImages";
import { IProductImageItem } from "types/IProductImageItem";

import styles from "./styles.module.scss";

interface ProductGalleryProps {
  images: IProductImageOffer[];
  noImageLink: string;
  activeImageIndex: number;
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ProductGalleryDesktop: React.FC<ProductGalleryProps> = memo(
  (props) => {
    const [isModalImageOpen, setIsModalImageOpen] = useState(false);
    const [canScrollStart, setCanScrollStart] = useState<boolean>(false);
    const [canScrollEnd, setCanScrollEnd] = useState<boolean>(false);
    const subImagesWrapperRef = useRef<HTMLDivElement | null>(null);
    const imagesCacheRef = useRef<IImagesCache>({});
    const modalImageIndex = useRef<number>(0);
    const modalImageRef = useRef<HTMLDivElement | null>(null);
    const subImageRefs = useRef<(HTMLButtonElement | null)[]>([]);

    const handleImageAction = useCallback(
      (imageIndex: number) => {
        props.setActiveImageIndex(imageIndex);
      },
      [props]
    );

    const scrollSubImages = (direction: "start" | "end") => {
      if (subImagesWrapperRef.current) {
        const scrollAmount = subImagesWrapperRef.current.clientHeight;
        subImagesWrapperRef.current.scrollBy({
          top: direction === "end" ? scrollAmount : -scrollAmount,
          behavior: "smooth"
        });
      }
    };

    const updateScrollButtonsVisibility = () => {
      if (subImagesWrapperRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          subImagesWrapperRef.current;
        setCanScrollStart(scrollTop > 0);
        setCanScrollEnd(scrollTop + clientHeight < scrollHeight);
      }
    };

    const handleOpenModalImage = useCallback(() => {
      modalImageIndex.current = props.activeImageIndex;
      setIsModalImageOpen(true);
    }, [props.activeImageIndex]);

    useEffect(() => {
      if (
        subImageRefs.current[props.activeImageIndex] &&
        subImagesWrapperRef.current
      ) {
        subImageRefs.current[props.activeImageIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }, [props.activeImageIndex]);

    useEffect(() => {
      const wrapper = subImagesWrapperRef.current;
      if (wrapper) {
        wrapper.addEventListener("scroll", updateScrollButtonsVisibility);
        updateScrollButtonsVisibility();
      }
      return () => {
        if (wrapper) {
          wrapper.removeEventListener("scroll", updateScrollButtonsVisibility);
        }
      };
    }, [props.images]);

    return (
      <div
        className={cn(styles["product-gallery"], {
          [styles["product-gallery--empty"]]: !props.images.length
        })}
      >
        {!props.images.length ? (
          <img
            src={props.noImageLink}
            alt=""
            className={styles["product-gallery__no-image"]}
          />
        ) : (
          <>
            <div className={styles["product-gallery__outer-images-wrapper"]}>
              {canScrollStart && (
                <button
                  onClick={() => scrollSubImages("start")}
                  className={cn(
                    styles["scroll-button"],
                    styles["scroll-button--start"]
                  )}
                >
                  <span />
                  <span />
                </button>
              )}
              <div
                className={styles["product-gallery__sub-images-wrapper"]}
                ref={subImagesWrapperRef}
              >
                {props.images.map((imageItem, index) => {
                  const imageValue = Object.values(
                    imageItem
                  )[0] as IProductImageItem;
                  return (
                    <button
                      key={index}
                      ref={(el) => (subImageRefs.current[index] = el)}
                      className={cn(styles["product-gallery__sub-image-item"], {
                        [styles["product-gallery__sub-image-item--active"]]:
                          index === props.activeImageIndex
                      })}
                      onClick={() => handleImageAction(index)}
                    >
                      <ProductProgressiveImage
                        thumbnail_q_100={imageValue.thumbnail_q_100}
                        thumbnail_q_0={imageValue.thumbnail_q_0}
                        thumbnail_q_50={imageValue.thumbnail_q_50}
                        original={imageValue.original}
                        imagesCache={imagesCacheRef.current}
                        customStyles={styles["product-gallery__sub-image"]}
                      />
                    </button>
                  );
                })}
              </div>
              {canScrollEnd && (
                <button
                  onClick={() => scrollSubImages("end")}
                  className={cn(
                    styles["scroll-button"],
                    styles["scroll-button--end"]
                  )}
                >
                  <span />
                  <span />
                </button>
              )}
            </div>
            <div
              className={styles["product-gallery__main-image-wrapper"]}
              onClick={handleOpenModalImage}
            >
              <ProductProgressiveImage
                thumbnail_q_100={
                  Object.values(props.images[props.activeImageIndex])[0]
                    .thumbnail_q_100
                }
                thumbnail_q_0={
                  Object.values(props.images[props.activeImageIndex])[0]
                    .thumbnail_q_0
                }
                thumbnail_q_50={
                  Object.values(props.images[props.activeImageIndex])[0]
                    .thumbnail_q_50
                }
                original={
                  Object.values(props.images[props.activeImageIndex])[0]
                    .original
                }
                imagesCache={imagesCacheRef.current}
                customStyles={styles["product-gallery__main-image"]}
              />
            </div>
          </>
        )}
        {isModalImageOpen && props.images.length && (
          <ProductModalImage
            originalSrc={
              Object.values(props.images[modalImageIndex.current])[0].original
                .link
            }
            modalRef={modalImageRef}
            onClose={() => setIsModalImageOpen(false)}
            imagesCache={imagesCacheRef.current}
          />
        )}
      </div>
    );
  }
);

ProductGalleryDesktop.displayName = "ProductGalleryDesktop";
