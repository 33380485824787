import {
  CustomError,
  getErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";
import { getUserToken } from "../utils/localStorage";
import { fetchPrivate } from "utils/fetchPrivate";
import { ICheaperOrder } from "types/ICheaperOrder";

export interface ICheaperOrderData {
  comment: string;
  login: string;
  name: string;
  offerId: number;
}

interface ICheaperOrderRequestData {
  comment: string;
  contact: string;
  contact_name: string;
  offer_id: number;
}

export const sendPublicCheaperOrder = async (
  order: ICheaperOrderData
): Promise<ICheaperOrder> => {
  const data: ICheaperOrderRequestData = {
    comment: order.comment,
    contact: order.login,
    contact_name: order.name,
    offer_id: order.offerId
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/cheaper-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При отправке сообщения о нашли дешевле произошла необработанная ошибка",
        500
      );
    }

    const responseData: IGenericResponseRoot<ICheaperOrder> = await res
      .clone()
      .json();

    if (!res.ok) {
      const errorData = getErrorData(responseData);
      throw new CustomError(errorData.message, errorData?.code);
    }

    if (responseData.response === null) {
      throw new CustomError(
        "Не получилось получить ответ на запрос нашли дешевле"
      );
    }

    return responseData.response;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(
      "При отправке сообщения о нашли дешевле для публичного апи произошла ошибка",
      errorData
    );
    throw new CustomError(errorData.message, errorData?.code);
  }
};

export const sendPrivateCheaperOrder = async (
  order: ICheaperOrderData
): Promise<ICheaperOrder> => {
  const token = getUserToken();
  const data: ICheaperOrderRequestData = {
    comment: order.comment,
    contact: order.login,
    contact_name: order.name,
    offer_id: order.offerId
  };

  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/cheaper-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При отправке сообщения о нашли дешевле произошла необработанная ошибка",
        500
      );
    }

    const responseData: IGenericResponseRoot<ICheaperOrder> = await res
      .clone()
      .json();

    if (!res.ok) {
      const errorMessage = getErrorData(responseData);
      throw new CustomError(errorMessage.message, errorMessage?.code);
    }

    if (responseData.response === null) {
      throw new CustomError(
        "Не получилось получить ответ на запрос нашли дешевле"
      );
    }

    return responseData.response;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(
      "При отправке сообщения о нашли дешевле для приватного апи произошла ошибка",
      errorData
    );
    throw new CustomError(errorData.message, errorData?.code);
  }
};

export const cheaperApi = {
  sendPublicCheaperOrder,
  sendPrivateCheaperOrder
};
