import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CustomDropDown from "../../../../components/CustomDropDown/CustomDropDown";
import { IFooteLinkItem } from "types/IFooterLinkItem";

import styles from "./styles.module.scss";

interface ListWithLinksProps {
  title: string;
  list: IFooteLinkItem[];
}

const ListWithLinks: React.FC<ListWithLinksProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);


  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  const onScrollContentToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handlerClickLink = () => {
    onScrollContentToTop();
  };

  const handleMediaQueryChange = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 810px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    if (mediaQuery.matches) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    return () => {
      mediaQuery.addEventListener("change", handleMediaQueryChange);
    };
  }, [handleMediaQueryChange]);

  return (
    <CustomDropDown
      isOpen={isOpen}
      containerStyles={styles.dropdown}
      dropdownElementStyles={styles["dropdown-content"]}
    >
      {{
        buttonElement: (
          <>
            <button className={styles.button} onClick={onClickHandler}>
              <p className={styles["catalog-list__title"]}>{props.title}</p>
              <span className={styles["catalog-list__symbol"]}>{isOpen ? "-" : "+"}</span>
            </button>
            <div className={styles["button--disabled"]}>
              <p className={styles["catalog-list__title"]}>{props.title}</p>
            </div>
          </>
        ),
        dropdownElement: (
          <div className={styles["catalog-list"]}>
            {props.list.map((item, idx) => {
              return (
                <Link
                  key={idx}
                  to={item.link}
                  onClick={handlerClickLink}
                  className={styles["catalog-list__item"]}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
        ),
      }}
    </CustomDropDown>
  );
};

export default ListWithLinks;