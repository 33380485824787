import authenticityIcon from "../../assets/icons/shopping_advantage-icons/authenticity.svg";
import checkIcon from "../../assets/icons/shopping_advantage-icons/check.svg";
import deliveryIcon from "../../assets/icons/shopping_advantage-icons/delivery.svg";
import payIcon from "../../assets/icons/shopping_advantage-icons/pay.svg";
import returnIcon from "../../assets/icons/shopping_advantage-icons/return.svg";
import secureItem from "../../assets/icons/shopping_advantage-icons/secure.svg";

export default {
  authenticityIcon,
  checkIcon,
  deliveryIcon,
  payIcon,
  returnIcon,
  secureItem,
};