import React from "react";

import styles from "./styles.module.scss";

interface QuantitySelectorProps {
  quantity: number;
  onIncreaseQuantity: () => void;
  onDecreaseQuantity: () => void;
}

export const QuantitySelector: React.FC<QuantitySelectorProps> = (props) => {
  return (
    <div className={styles["quantity-selector"]}>
      <button
        className={styles["quantity-selector__action-button"]}
        onClick={props.onDecreaseQuantity}
      >
        -
      </button>
      <p className={styles["quantity-selector__amount"]}>{props.quantity}</p>
      <button
        className={styles["quantity-selector__action-button"]}
        onClick={props.onIncreaseQuantity}
      >
        +
      </button>
    </div>
  );
};
