import styles from "./styles.module.scss";

const DolymePayment: React.FC = () => {
  return (
    <>
      <h3 className={styles.title}>Оплата Долями</h3>
      <ol className={styles.list}>
        <li className={styles.list__item}>
          <p className={styles.list__item__text}>
            Добавьте товары в корзину на сайте Парфюмарт и перейдите к оплате.
          </p>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__text}>
          На странице оплаты выберите &quot;Оплатить Долями&quot;, а затем укажите реквизиты
          карты, с которой спишется первая доля.
          </p>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__text}>
          Оплата делится на четыре части: первая спишется сразу, следующие три —
          через каждые две недели.
          </p>
        </li>
        <li className={styles.list__item}>
          <p className={styles.list__item__text}>
          Перед каждым платежом присылается напоминание о дате списания.
          </p>
        </li>
        <p className={styles.list__item__text_postscript}>
        Ознакомиться с подробными условиями можно на сайте{" "}
          <a
            href="https://dolyame.ru"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.list__item__link}
          >
            {" "}
          https://dolyame.ru
          </a>
        </p>
      </ol>
    </>
  );
};

export default DolymePayment;
