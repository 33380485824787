import close from "../../../assets/icons/svg/cross-rounded.svg";
import logo from "../../../assets/parfumart-logo.svg";
import styles from "./styles.module.scss";

import cn from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { foundCheaperShema } from "../../../schemas/foundCheaperShema";
import CustomButton from "../../CustomButton/CustomButton";
import { cheaperApi, ICheaperOrderData } from "../../../api/cheaperApi";
import { getUserToken } from "../../../utils/localStorage";
import { useAppSelector } from "store/reduxHooks";
import { getErrorData } from "utils/getErrorData";

interface IContentFoundCheaperProps {
  onClose: () => void;
  modalRef?: React.RefObject<HTMLDivElement> | null;
  currentOfferId: number;
}

const ContentFoundCheaper: React.FC<IContentFoundCheaperProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);

  const formik = useFormik<ICheaperOrderData>({
    initialValues: {
      comment: "",
      login: user.email || (user.phone ? `+${user.phone}` : ""),
      name: user.name || "",
      offerId: props.currentOfferId
    },
    validationSchema: foundCheaperShema,
    onSubmit: async (values, { setSubmitting }) => {
      const token = getUserToken();
      const apiCall = token
        ? cheaperApi.sendPrivateCheaperOrder
        : cheaperApi.sendPublicCheaperOrder;

      try {
        const response = await apiCall(values);
        if (response.comment) {
          props.onClose();
          toast("Спасибо за ваше сообщение! Мы обязательно свяжемся с вами.");
        }
      } catch (err) {
        const errorData = getErrorData(err);
        toast.error("При попытке отправить сообщение произошла ошибка");
        // eslint-disable-next-line no-console
        console.error(
          "Не удалось отправить сообщение о нашли дешевле. Причина:",
          errorData
        );
      } finally {
        setSubmitting(false);
      }
    },
    validateOnBlur: false
  });

  function formatPhoneNumber(value: string) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return value;
    } else if (value.includes("@")) {
      return value.replace(/^\+/, "");
    } else if (!value.startsWith("+") && !emailPattern.test(value)) {
      return `+${value}`;
    } else {
      return value;
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (name === "login") {
      const formattedValue = formatPhoneNumber(value);
      formik.setFieldValue(name, formattedValue, false);
      formik.setFieldTouched(name, true, false);
    }
  }

  return (
    <div className={styles.modal__container} ref={props.modalRef}>
      <div className={styles["modal__container_logo-wrapper"]}>
        <img
          src={logo}
          className={styles["modal__container_logo-wrapper-logo"]}
        />
        <button
          className={styles["modal__container_logo-wrapper_btn"]}
          onClick={() => props.onClose()}
        >
          <img
            src={close}
            className={styles["modal__container_logo-wrapper_btn-close"]}
          />
        </button>
      </div>
      <div className={styles["modal__container_heading-wrapper"]}>
        <h1 className={styles["modal__container_heading-wrapper-heading"]}>
          Нашли дешевле? Сообщите нам!
        </h1>
      </div>
      <div className={styles["modal__container_input-wrapper"]}>
        <p className={styles["modal__container_input-wrapper-form-error"]}>
          {formik.errors.comment}
        </p>
        <textarea
          placeholder="Ссылка на страницу и комментарий"
          value={formik.values.comment}
          name="comment"
          onChange={(e) => formik.handleChange(e)}
          className={cn(
            styles["modal__container_input-wrapper-input"],
            styles["modal__container_input-wrapper-comment"],
            {
              [styles["modal__container_input-wrapper-error"]]:
                formik.errors.comment
            }
          )}
        />
        <p className={styles["modal__container_input-wrapper-form-error"]}>
          {formik.errors.login}
        </p>
        <input
          placeholder="Ваш номер телефона или email"
          value={formik.values.login}
          name="login"
          onBlur={handleBlur}
          onChange={(e) => formik.handleChange(e)}
          className={cn(styles["modal__container_input-wrapper-input"], {
            [styles["modal__container_input-wrapper-error"]]:
              formik.errors.login
          })}
        />
        <p className={styles["modal__container_input-wrapper-form-error"]}>
          {formik.errors.name}
        </p>
        <input
          placeholder="Ваше имя"
          value={formik.values.name}
          name="name"
          onChange={(e) => formik.handleChange(e)}
          className={cn(styles["modal__container_input-wrapper-input"], {
            [styles["modal__container_input-wrapper-error"]]: formik.errors.name
          })}
        />
      </div>
      <CustomButton
        title="Отправить"
        className={styles["modal__container-btn-send"]}
        isDisabled={
          !!formik.errors.comment ||
          !!formik.errors.login ||
          !!formik.errors.name
        }
        onClick={formik.submitForm}
      />
    </div>
  );
};

export default ContentFoundCheaper;
