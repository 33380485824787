import { IBanner, ITabInfo, LinksForButton } from "./bestsellersConstants";
import selectiveParfum from "../../assets/banners/selective/selective.png";
import { ICategoryTab } from "types/ICategoryTab";


export const selectiveBannersData: IBanner[] = [
  { id: 1, imageUrl: selectiveParfum, text: "Селективная\nпарфюмерия" },
  // { id: 2, imageUrl: bennerParfumart },
  // { id: 3, imageUrl: banner1 }
];

export const selectiveQueryOptions = {
  sort_by: "showed",
  order_by: "desc",
  categories: "parfumeria",
  max_price: "493638",
  min_price: "15000"
};

export const linksProfitButtonSelective: LinksForButton = {
  firstProfitBlock: "/catalog/parfumeria?min_price=15000&max_price=493638&limit=18&offset=0",
  secondProfitBlock: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-zensin",
  thirdProfitBlock: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-muzcin",
};

export const womensFragrancesInfo: ITabInfo[] = [
  { title: "Туалетная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-zensin&product_types=tualetnaa-voda" },
  { title: "Парфюмерная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-zensin&product_types=parfumernaa-voda" },
  { title: "Духи", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-zensin&product_types=duhi" },
  { title: "Духи сухие и масляные", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-zensin&product_types=duhi-maslannye" }
];

export const mensFragrancesInfo: ITabInfo[] = [
  { title: "Туалетная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-muzcin&product_types=tualetnaa-voda" },
  { title: "Парфюмерная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-muzcin&product_types=parfumernaa-voda" },
  { title: "Духи", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-muzcin&product_types=duhi" },
  { title: "Духи сухие и масляные", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=dla-muzcin&product_types=duhi-maslannye" }
];

export const unisexFragrancesInfo: ITabInfo[] = [
  { title: "Туалетная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=uniseks&product_types=tualetnaa-voda" },
  { title: "Парфюмерная вода", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=uniseks&product_types=parfumernaa-voda" },
  { title: "Духи", link: "/catalog/parfumeria?min_price=15000&max_price=493638&properties%5Bpol%5D=uniseks&product_types=duhi" },
  { title: "Духи сухие и масляные", link: "/catalog/parfumeria?min_price=15000&max_price=493638&product_types=duhi-maslannye&properties%5Bpol%5D=uniseks" }
];

const pol = "pol";

const womensQueryParams: Record<string, string | undefined> = {
  sort_by: "showed",
  order_by: "desc",
  [`properties[${pol}]`]: "dla-zensin",
  max_price: "493638",
  min_price: "15000"
};

const mensQueryParams: Record<string, string | undefined> = {
  sort_by: "showed",
  order_by: "desc",
  [`properties[${pol}]`]: "dla-muzcin",
  max_price: "493638",
  min_price: "15000"
};

const unisexQueryParams: Record<string, string | undefined> = {
  sort_by: "showed",
  order_by: "desc",
  [`properties[${pol}]`]: "uniseks",
  max_price: "493638",
  min_price: "15000"
};

export const womensFragrancesTabs: ICategoryTab[] = [
  { productType: "tualetnaa-voda", category: "parfumeria", additionalQuery: womensQueryParams },
  { productType: "parfumernaa-voda", category: "parfumeria", additionalQuery: womensQueryParams },
  { productType: "duhi", category: "parfumeria", additionalQuery: womensQueryParams },
  { productType: "duhi-maslannye", category: "parfumeria", additionalQuery: womensQueryParams }
];

export const mensFragrancesTabs: ICategoryTab[] = [
  { productType: "tualetnaa-voda", category: "parfumeria", additionalQuery: mensQueryParams },
  { productType: "parfumernaa-voda", category: "parfumeria", additionalQuery: mensQueryParams },
  { productType: "duhi", category: "parfumeria", additionalQuery: mensQueryParams },
  { productType: "duhi-maslannye", category: "parfumeria", additionalQuery: mensQueryParams }
];

export const unisexFragrancesTabs: ICategoryTab[] = [
  { productType: "tualetnaa-voda", category: "parfumeria", additionalQuery: unisexQueryParams },
  { productType: "parfumernaa-voda", category: "parfumeria", additionalQuery: unisexQueryParams },
  { productType: "duhi", category: "parfumeria", additionalQuery: unisexQueryParams },
  { productType: "duhi-maslannye", category: "parfumeria", additionalQuery: unisexQueryParams }
];