import { useCallback, useMemo, useRef } from "react";
import styles from "./styles.module.scss";
import LandingProductCard from "../LandingProductCard/LandingProductCard";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { useAppSelector } from "store/reduxHooks";
import { IProductWithOffers } from "types/IProduct";

interface BestsellersLikeItBlockProps {
  products: IProductWithOffers[];
  isLoadingProducts: boolean;
  handleChangeShouldGetProductsStatus: (value: boolean) => void;
  isFirstLoad: boolean;
}

const LandingLikeItBlock: React.FC<BestsellersLikeItBlockProps> = ({
  products,
  isLoadingProducts,
  handleChangeShouldGetProductsStatus,
  isFirstLoad
}) => {
  const { deviceType } = useAppSelector((state) => state.user);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastProductElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoadingProducts || isFirstLoad) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleChangeShouldGetProductsStatus(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingProducts, handleChangeShouldGetProductsStatus, isFirstLoad]
  );

  const skeletonCount = useMemo(() => {
    if (deviceType.isLargeDesktop) return 12;
    if (deviceType.isDesktop) return 8;
    if (deviceType.isTablet) return 6;
    return 3;
  }, [deviceType]);

  return (
    <div className={styles.container} itemScope itemType="https://schema.org/ItemList">
      <meta itemProp="name" content="Вам может понравиться" />
      <h2 className={styles.title}>Вам может понравиться</h2>
      <div className={styles.products}>
        {products.length === 0 &&
          isLoadingProducts &&
          Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonBestsellerCard key={index} />
          ))}
        {products.map((product, index) => (
          <div key={product.id} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <meta itemProp="position" content={`${index + 1}`} />
            <LandingProductCard
              key={`${product.id}-${index}`}
              product={product}
              cardRef={
                products.length === index + 1 ? lastProductElementRef : undefined
              }
            />
          </div>
        ))}
        {products.length > 0 &&
          isLoadingProducts &&
          Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonBestsellerCard key={index} />
          ))}
      </div>
    </div>
  );
};

export default LandingLikeItBlock;
