import { useEffect } from "react";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import { ProductCard } from "components";

import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { fetchProfitBanner } from "store/banners/bannerThunks/bannerThunks";
import ProgressiveImage from "components/ProgressiveImage/ProgressiveImage";
import { SkeletonImg } from "components/UI/Skeleton/SkeletonImg/SkeletonImg";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { SkeletonCard } from "components/UI/Skeleton/SkeletonCard/SkeletonCard";

import cn from "classnames";
import styles from "./styles.module.scss";

const ProfitBlock: React.FC = () => {
  const dispatch = useAppDispatch();
  const { profitData, bannerImage, title, isLoading, errorMessage: error} = useAppSelector((state) => state.banners.profitBanner);

  useEffect(() => {
    if (!profitData) {
      dispatch(fetchProfitBanner());
    }
  }, [dispatch, profitData]);


  if (isLoading) {
    return (
      <section className={cn(styles.root, { [styles.loading]: isLoading })}>
        <div className={cn(styles.banner, { [styles.banner__loading]: isLoading })}>
          <SkeletonImg
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "900px",
              maxHeight: "1007px",
              margin: 0,
              padding: 0
            }}
          />
        </div>
        <div className={styles.products}>
          <SkeletonLine style={{
            width: "300px",
            height: "24px",
          }}/>
          <div className={styles.products__list}>
            {Array(4).fill(null).map((_, index) => (
              <div className={styles.products__card} key={index}>
                <SkeletonCard showLabels={false} />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  };

  if (!profitData || profitData.length === 0 || !bannerImage) {
    return null;
  }

  const titleParts: string[] = title ? title.split(" ") : [];
  const topText = titleParts.slice(0, 2).join(" ");
  const bottomText1 = titleParts[2];
  const bottomText2 = titleParts.slice(3).join(" ");

  return (
    <section className={styles.root}>
      <div className={styles.banner}>
        <ProgressiveImage
          lowQualitySrc={bannerImage}
          highQualitySrc={bannerImage}
          alt="banner-image"
          className={styles.banner__image}
        />
        <div className={styles.banner__text}>
          <div className={styles["banner__text-top"]}>{topText}</div>
          <div className={styles["banner__text-bottom"]}>
            <div>{bottomText1}</div>
            <div>{bottomText2}</div>
          </div>
        </div>
      </div>
      <div className={styles.products}>
        <SectionTitle title="Подобрали для вас лучшее" containerStyles={styles.products__title}/>
        <div className={styles.products__list}>
          {profitData.map((product) => (
            <div className={styles.products__card} key={product.id}>
              <ProductCard
                product={product}
                showLabels={false}
                customHeartClass={styles["custom-heart-position"]}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProfitBlock;