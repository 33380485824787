import { ICategoryTab } from "types/ICategoryTab";
import imageHair from "../../assets/bestseller/tabs/categories1.png";
import imageBody from "../../assets/bestseller/tabs/categories2.png";
// import imageFace from "../assets/bestseller/tabs/categories3.png";
// import imageMenCosmetic from "../assets/bestseller/tabs/categories5.png";
import imageParfum from "../../assets/bestseller/tabs/categories4.png";
import bennerNewYear from "../../assets/banners/bestsellers/new-year-banner.png";

export interface LinksForButton {
  [key: string]: string;
}

export interface CategoryTab {
  title: string;
  image: string;
  link: string;
}

export interface ITabInfo {
  title: string;
  link: string;
}

export interface IBanner {
  id: number;
  imageUrl: string;
  text?: string;
  link?: string;
}

export const linksProfitButton: LinksForButton = {
  firstProfitBlock: "/catalog/parfumeria?badges=bestseller&properties[pol]=dla-zensin",
  secondProfitBlock: "/catalog/parfumeria?badges=bestseller&properties[pol]=dla-muzcin",
  thirdProfitBlock: "/catalog/parfumeria?properties[pol]=uniseks&badges=bestseller",
};

// export const linksForTabsButton: LinksForButton = {
//   hairСareTab: "/catalog/kosmetika?properties[kategoria]=dla-volos&badges=bestseller",
//   parfumeriaTab: "/catalog/parfumeria&badges=bestseller",
//   bodyCareTab: "/catalog/kosmetika?properties[kategoria]=dla-tela&badges=bestseller"
// };

// export const productProfitIds: number[] = [
//   5407, 5531, 2852, 5969, 1283, 5194, 2638, 4327, 191, 2877, 6055, 8055,
//   6161, 8463, 3435, 6419, 2262, 185
// ];

export const hairCareTabIds: number[][] = [
  [15667, 334, 15666 ], // краска для волос
  [356, 15668 ], // оксилители
  [19391, 12963, 19661, 24651] // маска для волос
];

export const bodyCareTabIds: number[][] = [
  [10228], // для душа
  [9262] // дезодоранты
];

export const parfumeriaTabIds: number[][] = [
  [1384, 7888, 2217, 2223],  // Туалетная вода
  [2797, 16588, 5846, 3300], // Парфюмерная вода
  [2229, 5472, 396, 2265], // Духи
  [6737, 5639, 6731, 700]  // Духи масляные
];

export const categories: CategoryTab[] = [
  { title: "Парфюм", image: imageParfum, link: "/catalog/parfumeria?badges=bestseller" },
  { title: "Уход за волосами", image: imageHair, link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-volos&badges=bestseller" },
  { title: "Уход за телом", image: imageBody, link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-tela&badges=bestseller" },
  // { title: "Уход для лица", image: imageFace, link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-lica&badges=bestseller" },
  // { title: "Мужская косметика", image: imageMenCosmetic, link: "/catalog/kosmetika?properties%5Bklassifikacia%5D=muzskaa-kosmetika&badges=bestseller" },
];

const commonQueryParams: Record<string, string | undefined> = {
  sort_by: "updated_at",
  order_by: "desc",
  badges: "bestseller"
};

export const hairCareTabs: ICategoryTab[] = [
  { productType: "kraska-dla-volos", category: "kosmetika", additionalQuery: commonQueryParams },
  { productType: "okislitel", category: "kosmetika", additionalQuery: commonQueryParams },
  { productType: "maska-dla-volos", category: "kosmetika", additionalQuery: commonQueryParams }
];

export const bodyCareTabs: ICategoryTab[] = [
  { productType: "maslo-dla-dusa", category: "kosmetika", additionalQuery: commonQueryParams },
  { productType: "dezodorant", category: "kosmetika", additionalQuery: commonQueryParams }
];

export const hairCareInfo: ITabInfo[] = [
  { title: "Краска для волос", link: "/catalog/kosmetika?properties[kategoria]=dla-volos&badges=bestseller&product_types=kraska-dla-volos" },
  { title: "Окислители", link: "/catalog/kosmetika?properties[kategoria]=dla-volos&badges=bestseller&product_types=okislitel" },
  { title: "Маска для волос", link: "/catalog/kosmetika?properties[kategoria]=dla-volos&product_types=maska-dla-volos&badges=bestseller" }
];

export const bodyCareInfo: ITabInfo[] = [
  { title: "Для душа", link: "/catalog/kosmetika?properties[kategoria]=dla-tela&product_types=gel-dla-dusa%2Cgel-dla-tela%2Ckrem-dla-dusa%2Clos-on-dla-tela%2Cmaslo-dla-volos%2Cmaslo-dla-vanny%2Cmaslo-dla-dusa%2Cmylo%2Cskrab-dla-tela%2Csredstvo-dla-vanny%2Cemul-sia-dla-tela&badges=bestseller" },
  { title: "Дезодоранты", link: "/catalog/kosmetika?product_types=dezodorant&badges=bestseller" }
];

export const bestsellersBannersData: IBanner[] = [
  { id: 1, imageUrl: bennerNewYear, text: "Хиты\nпродаж" },
  // { id: 2, imageUrl: bennerParfumart },
  // { id: 3, imageUrl: banner1 }
];

export const bestsellersQueryOptions = {
  sort_by: "updated_at",
  order_by: "asc",
  categories: "parfumeria",
  badges: "bestseller"
};