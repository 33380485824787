import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";

interface BestsellersCategoryItemProps {
  title: string;
  image: string;
  link: string;
}

const LandingCategoryItem: React.FC<BestsellersCategoryItemProps> = ({ title, image, link }) => {
  return (
    <Link to={link} className={styles.item} itemProp="url">
      <div className={styles.wrapper} itemScope itemType="https://schema.org/ImageObject">
        <link itemProp="contentUrl" href={image} />
        <span itemProp="image">
          <SingleImageLoader src={image} alt={title} className={styles.image} />
        </span>
        <div className={styles.title} itemProp="name">{title}</div>
      </div>
    </Link>
  );
};

export default LandingCategoryItem;
