import { IPaymentMethod } from "types/IPaymentMethod";
import { PAYMENT_METHODS } from "../../../../../utils/constants";

import styles from "./styles.module.scss";
import {
  DefaultPaymentMethodItem,
  DolyamiPaymentMethodItem
} from "pages/UserCart/Checkout/components/PaymentMethod/components/PaymentMethodItem/PaymentMethodItem";
import { useAppSelector } from "store/reduxHooks";
import { PaymentMethodSkeleton } from "pages/UserCart/Checkout/components/PaymentMethod/components/PaymentMethodSkeleton/PaymentMethodSkeleton";
import { useMemo } from "react";

const allowedTypes = [
  "tinkoff_pay",
  "yandex_pay",
  "yandex_split",
  "sbp",
  "sber_pay",
  "dolymi",
  "card_online",
  "card_receipt",
  "yookassa",
  "cash",
  "mokka"
];

const PaymentMethod: React.FC = () => {
  const { paymentTypes } = useAppSelector((state) => state.user);

  const filteredPaymentMethod = (item: IPaymentMethod) => {
    return (
      allowedTypes.includes(item.type) &&
      Object.keys(paymentTypes.types).includes(item.type)
    );
  };

  const skeletonArray = Array.from({ length: 3 });

  const isPaymentError = useMemo(() => {
    return (
      !Object.keys(paymentTypes.types).length &&
      !paymentTypes.isPaymentTypesLoading &&
      paymentTypes.errorMessage
    );
  }, [
    paymentTypes.errorMessage,
    paymentTypes.isPaymentTypesLoading,
    paymentTypes.types
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <span className={styles.title__step}>3/3</span>
        <h4 className={styles.title__text}>Способ оплаты</h4>
        <div />
      </div>
      {paymentTypes.isPaymentTypesLoading ? (
        skeletonArray.map((_, index) => <PaymentMethodSkeleton key={index} />)
      ) : !isPaymentError ? (
        PAYMENT_METHODS.map((item, idx) => {
          if (!filteredPaymentMethod(item)) return null;
          const paymentDetails = paymentTypes.types[item.type];
          if (item.type === "dolymi") {
            return (
              <DolyamiPaymentMethodItem
                key={`${item.type}-${idx}`}
                type={item.type}
                title={item.title}
              />
            );
          }
          return (
            <DefaultPaymentMethodItem
              key={`${item.type}-${idx}`}
              type={item.type}
              title={item.title}
              limit={paymentDetails?.limit || null}
            />
          );
        })
      ) : (
        <p className={styles.warning}>Доступных способов оплаты нет</p>
      )}
    </div>
  );
};

export default PaymentMethod;
