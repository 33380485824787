import { useCallback, useRef, useState } from "react";
import { ru } from "date-fns/locale";
import { USER_INFO_FIELDS } from "../../../../../utils/constants/formItems";
import phoneNumberMask from "../../../../../utils/inputMasks/phoneNumberMask";

import CustomNotification from "../../../../../components/CustomNotification/CustomNotification";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

import cn from "classnames";
import { FormikProps } from "formik";
import { IFormData } from "pages/PersonalArea/components/PersonalData/PersonalData";
import { months } from "utils/constants/months";
import { maskDate } from "utils/helpers/maskDate";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import useOutsideClick from "utils/hooks/useOutsideClick";

registerLocale("ru", ru);

interface IMainUserInfoProps {
  mainUserInfoRef: React.RefObject<HTMLFormElement>;
  formik: FormikProps<IFormData>;
  gender: string;
  disableGenderSelect: boolean;
  disabledDobSelect: boolean;
  showUserDataNotification: boolean;
  onClickSelectGender: (gender: string) => void;
  onSubmitHandler: () => void;
  isFormChanged: boolean;
}

const MainUserInfo: React.FC<IMainUserInfoProps> = (props) => {
  const outside = useRef<HTMLDivElement | null>(null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const years = Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (_, i) => (1900 + i).toString());

  const onClickOpenDateInput = () => {
    if (props.disabledDobSelect) {
      return;
    }
    if (!isDatePickerVisible) {
      setDatePickerVisible(true);
    }
  };

  const onChangeGender = (value: string) => {
    if (props.gender === value) {
      props.onClickSelectGender("");
      return;
    }
    props.onClickSelectGender(value);
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputType: string
  ) => {
    if (inputType !== "tel") {
      return props.formik.handleChange(e);
    }
    phoneNumberMask(e);
    props.formik.handleChange(e);
  };

  const handleScroll = () => {
    setDatePickerVisible(false);
  };

  useOutsideClick({
    ref: outside,
    cb: () => {
      setDatePickerVisible(false);
      handleScroll();
    }
  });


  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      const formattedDate = date.toLocaleDateString("en-CA");
      props.formik.setFieldValue("dob", formattedDate);
      setInputValue(formattedDate);
    } else {
      props.formik.setFieldValue("dob", "");
      setInputValue("");
    }
    setDatePickerVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.setFieldValue, setDatePickerVisible]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    maskDate(e);
    const value = e.target.value;
    setInputValue(value);
    if (isValidDate(value)) {
      props.formik.setFieldValue("dob", value);
    }
  };


  const isValidDate = (dateString: string): boolean => {
    const regEx = /^\d{2}-\d{2}-\d{4}$/;
    const match = dateString.match(regEx);
    if (match === null) return false;

    const [day, month, year] = dateString.split("-").map(Number);
    const d = new Date(year, month - 1, day);
    const dNum = d.getTime();
    if (isNaN(dNum)) return false;

    return d.getDate() === day && d.getMonth() + 1 === month && d.getFullYear() === year;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (e.key === "Backspace" && (target.selectionStart === 5 || target.selectionStart === 8)) {
      e.preventDefault();
      target.setSelectionRange(target.selectionStart - 1, target.selectionStart - 1);
    }
  };

  const handleChangeRaw = (event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (event && event.target && event.target instanceof HTMLInputElement) {
      const e = event as unknown as React.ChangeEvent<HTMLInputElement>;
      maskDate(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setInputValue("");
      props.formik.setFieldValue("dob", "");
    }
  };

  const getDayClassName = useCallback((date: Date) => {
    const selectedDate = props.formik.values.dob ? new Date(props.formik.values.dob) : null;
    return date.toDateString() === selectedDate?.toDateString() ? styles["selected-day"] : "";
  }, [props.formik.values.dob]);

  return (
    <form
      method="post"
      onSubmit={props.formik.handleSubmit}
      className={styles.root}
      ref={props.mainUserInfoRef}
      data-testid="formik-form"
    >
      {USER_INFO_FIELDS.map((item) => {
        return (
          <div key={item.id} className={styles.row}>
            <p className={styles.row__title}>{item.title}</p>
            <div className={styles["row__input-wrapper"]}>
              <input
                name={item.name}
                type={item.inputType}
                value={props.formik.values[item.name]}
                onChange={(e) => onChangeHandler(e, item.inputType)}
                className={cn(styles.row__input, {
                  [styles.row__input_phone]: item.name === "phone"
                })}
                readOnly={item.isReadOnly}
                data-testid={item.name}
              />
              {item.isRequiredField && (
                <span className={styles["row__required-symbol"]}>*</span>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles.row}>
        <span className={styles.row__title}>Пол</span>
        <div className={styles.gender}>
          <button
            type="button"
            className={cn(styles.gender__button, {
              [styles["gender__button--disabled"]]: props.disableGenderSelect
            })}
            onClick={() => onChangeGender("male")}
          >
            <div className={styles.gender__indicator}>
              {props.gender === "male" && (
                <div className={styles["gender__indicator--active"]} />
              )}
            </div>
            <span className={styles.gender__title}>Мужской</span>
          </button>
          <button
            type="button"
            className={cn(styles.gender__button, {
              [styles["gender__button--disabled"]]: props.disableGenderSelect
            })}
            onClick={() => onChangeGender("female")}
          >
            <div className={styles.gender__indicator}>
              {props.gender === "female" && (
                <div className={styles["gender__indicator--active"]} />
              )}
            </div>
            <span className={styles.gender__title}>Женский</span>
          </button>
        </div>
      </div>
      <div className={styles.row}>
        <span className={styles.row__title}>Дата рождения</span>
        <div className={styles["dob-button-wrapper"]}>
          <CustomDatePicker
            locale="ru"
            dayClassName={getDayClassName}
            selected={props.formik.values.dob ? new Date(props.formik.values.dob) : null}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
            onChangeRaw={handleChangeRaw}
            placeholderText={"ДД-ММ-ГГГГ"}
            maxDate={new Date()}
            disabled={props.disabledDobSelect}
            years={years}
            months={months}
            onClickOpenDateInput={onClickOpenDateInput}
            handleInputChange={handleInputChange}
            handleKeyDown={handleKeyDown}
            handleBlur={handleBlur}
            inputValue={inputValue}
            disabledDobSelect={props.disabledDobSelect}
            data-testid="dob"
          />
        </div>
      </div>
      <div className={styles.row}>
        <CustomButton
          title="Сохранить данные"
          onClick={props.onSubmitHandler}
          type="button"
          className={cn(styles["save-button"], {
            [styles["save-button--disabled"]]: !props.isFormChanged
          })}
          isDisabled={!props.isFormChanged}
          data-testid="save-button"
        />
      </div>
      <CustomNotification showNotification={props.showUserDataNotification} />
    </form>
  );
};

export default MainUserInfo;
