import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { Link } from "react-router-dom";

import { setUserCurrentCity } from "../../store/user/user";
import useOutsideClick from "../../utils/hooks/useOutsideClick";

import CitiesSuggestionContent from "../../components/CitiesSuggestionContent/CitiesSuggestionContent";
import SelectCityModal from "../../components/SelectCityModal/SelectCityModal";
import CatalogTitles from "./CatalogTitles/CatalogTitles";
import UserBlock from "./UserBlock/UserBlock";

import geoIcon from "../../assets/header_icons/icon_geo.svg";
import parfumartLogo from "../../assets/parfumart-logo.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

const AdditionalHeader: React.FC = () => {
  const { currentCity, deviceType } = useAppSelector(state => state.user);
  const [isOpenCityModal, setIsOpenCityModal] = useState(false);
  const [showSelectCities, setShowSelectCities] = useState(false);
  const dispatch = useAppDispatch();

  const cityModalRef = useRef<HTMLDivElement | null>(null);
  const selectCitiesModalRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: cityModalRef,
    cb: () => setIsOpenCityModal(false),
    disableClickOutside: showSelectCities,
  });

  useOutsideClick({
    ref: selectCitiesModalRef,
    cb: () => setShowSelectCities(false),
  });

  const onCloseModals = () => {
    setIsOpenCityModal(false);
    setShowSelectCities(false);
  };

  const setCity = async (city: string): Promise<void> => {
    dispatch(setUserCurrentCity(city));
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles["city-select"]}>
          <img
            src={geoIcon}
            alt='geo_icon'
            className={styles["city-select_icon"]}
          />
          <div className={styles["city-select__info"]}>
            <button
              className={cn(styles["city-select__info_button"], {
                [styles["city-select__info_button--selected"]]: !!currentCity,
              })}
              onClick={() => setIsOpenCityModal(!isOpenCityModal)}
            >
              {!currentCity ? "Выбор города" : currentCity}
            </button>
            {isOpenCityModal && (
              <CitiesSuggestionContent
                cityTitle={currentCity}
                containerStyles={cn(styles["cities-suggestion-modal"], {
                  [styles["cities-suggestion-modal--shadow-disabled"]]:
                    showSelectCities,
                })}
                contentRef={cityModalRef}
                onClose={() => setIsOpenCityModal(false)}
                onShowSelectCities={() => setShowSelectCities(true)}
              />
            )}
            {showSelectCities && (
              <SelectCityModal
                modalRef={selectCitiesModalRef}
                onClose={() => setShowSelectCities(false)}
                onCloseCb={onCloseModals}
                setCity={setCity}
              />
            )}
          </div>
        </div>
        {!deviceType.isTablet && (
          <Link to='/'>
            <img
              src={parfumartLogo}
              alt='parfumart_logo'
              className={styles.logo}
            />
          </Link>
        )}
        <UserBlock />
      </div>
      <div className={styles["logo-wrapper-tablet"]}>
        {deviceType.isTablet && (
          <Link to='/'>
            <img
              src={parfumartLogo}
              alt='parfumart_logo'
              className={styles["logo-tablet"]}
            />
          </Link>
        )}
      </div>
      <CatalogTitles />
    </div>
  );
};

export default AdditionalHeader;
