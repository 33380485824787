import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import useSlider from "utils/hooks/useSlider";
import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import { IImagesCache } from "types/IImagesCache";
import { IProductImageOffer } from "utils/createProductImages";
import { IProductImageItem } from "types/IProductImageItem";

import DynamicalBullets from "components/UI/DynamicalBullets/DynamicalBullets";
import { ProductModalImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductModalImage/ProductModalImage";

import styles from "./styles.module.scss";

interface ProductGalleryMobileProps {
  images: IProductImageOffer[];
  noImageLink: string;
  activeImageIndex: number;
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ProductGalleryMobile: React.FC<ProductGalleryMobileProps> = ({
  images,
  noImageLink,
  activeImageIndex,
  // TODO - потенциально можно будет использовать setActiveImageIndex для взяимно обратного эффекта клика оффера и картинки
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActiveImageIndex
}) => {
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  const imagesCacheRef = useRef<IImagesCache>({});
  const modalImageIndex = useRef<number>(0);
  const modalImageRef = useRef<HTMLDivElement | null>(null);
  const prevActiveIndex = useRef<number>(activeImageIndex);

  const { currentSlide, isLoaded, availableDots, sliderRef, instanceRef } =
    useSlider({
      shouldBeLooped: true,
      shouldAutoSwitched: false
    });

  useEffect(() => {
    return () => {
      setIsModalImageOpen(false);
    };
  }, []);

  useEffect(() => {
    // Логика перелистования картинки по активному индексу выбора оффера
    if (prevActiveIndex.current !== activeImageIndex) {
      instanceRef?.current?.moveToIdx(activeImageIndex);
      prevActiveIndex.current = activeImageIndex;
    }
  }, [activeImageIndex, instanceRef]);

  const handleOpenModalImage = useCallback((imageIndex: number) => {
    modalImageIndex.current = imageIndex;
    setIsModalImageOpen(true);
  }, []);

  return (
    <div className={styles["mobile-gallery"]}>
      {!images.length ? (
        <img
          src={noImageLink}
          alt=""
          className={styles["mobile-gallery__no-image"]}
        />
      ) : (
        <div ref={sliderRef} className={cn("keen-slider")}>
          {images.map((item, idx) => {
            const imageValue = Object.values(item)[0] as IProductImageItem;
            return (
              <div
                key={`${idx}`}
                className={cn(
                  "keen-slider__slide",
                  styles["mobile-gallery__slide-custom"]
                )}
                onClick={() => handleOpenModalImage(idx)}
              >
                <ProductProgressiveImage
                  imagesCache={imagesCacheRef.current}
                  thumbnail_q_100={imageValue.thumbnail_q_100}
                  thumbnail_q_0={imageValue.thumbnail_q_0}
                  thumbnail_q_50={imageValue.thumbnail_q_50}
                  original={imageValue.original}
                  customStyles={styles["mobile-gallery__image"]}
                />
              </div>
            );
          })}
        </div>
      )}
      {availableDots?.length > 1 && isLoaded && instanceRef.current && (
        <div className={styles.pagination}>
          <DynamicalBullets
            availableDots={availableDots}
            currentSlide={currentSlide}
            instanceRefSlider={instanceRef}
            dotsStyles={styles["slider-dots"]}
          />
        </div>
      )}
      {isModalImageOpen && images.length && (
        <ProductModalImage
          originalSrc={
            Object.values(images[modalImageIndex.current])[0].original.link
          }
          modalRef={modalImageRef}
          onClose={() => setIsModalImageOpen(false)}
          imagesCache={imagesCacheRef.current}
        />
      )}
    </div>
  );
};
