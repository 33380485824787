import telegramIcon from "../../assets/icons/png/telegram-icon.png";
import vkIcon from "../../assets/icons/png/vk-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ContactsInnerContentProps {
  containerStyles?: string;
}

const ContactsInnerContent: React.FC<ContactsInnerContentProps> = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      <p className={styles["main-title"]}>Позвонить нам:</p>
      <p className={styles["phones-wrapper"]}>
        <span className={styles.subtitle}>8 (861) 290 21 10</span>
        <span className={styles.subtitle}>8 (918) 977 21 10</span>
      </p>
      <p className={styles["main-title"]}>Написать нам:</p>
      <p className={styles.subtitle}>client@parfumart.ru</p>
      <p className={styles["main-title"]}>Мы в социальных сетях:</p>
      <div className={styles["links-wrapper"]}>
        <a
          href={`${process.env.REACT_APP_SOCIAL_NET_LINK_VK}`}
          rel="noreferrer"
          target="_blank"
        >
          <img src={vkIcon} alt="vk-icon" />
        </a>
        <a
          href={`${process.env.REACT_APP_SOCIAL_NET_LINK_TLG}`}
          rel="noreferrer"
          target="_blank"
        >
          <img src={telegramIcon} alt="telegram-icon" />
        </a>
      </div>
    </div>
  );
};

export default ContactsInnerContent;
