import { SkeletonLine } from "../SkeletonLine/SkeletonLine";
import styles from "./styles.module.scss";
import Cosmetics from "../../../../assets/icons/svg/cosmetics.svg";

interface SkeletonCardProps {
  showLabels?: boolean;
}

export const SkeletonCard: React.FC<SkeletonCardProps> = ({
  showLabels = true
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        {showLabels && (
          <div className={styles.card__labels}>
            <SkeletonLine style={{ width: "60px" }} />
          </div>
        )}
        <span className={styles["card_img-wrapper"]}>
          <img
            src={Cosmetics}
            alt={"Loading img"}
            className={styles["card_img-wrapper-img"]}
          />
        </span>
        {showLabels && (
          <div className={styles.card__labels}>
            <SkeletonLine style={{ width: "100px" }} />
          </div>
        )}
        <SkeletonLine style={{ width: "80px" }} />
        <SkeletonLine style={{ width: "120px" }} />
        <SkeletonLine style={{ width: "110px" }} />
        {showLabels && (
          <div>
            <SkeletonLine style={{ width: "40px" }} />
          </div>
        )}
      </div>
    </div>
  );
};
