import InfoPage from "../InfoPage";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import BankCardPayment from "./Components/BankCardPayment/BankCardPayment";
import LinkToDelivery from "./Components/LinkToDelivery/LinkToDelivery";
import CashPayment from "./Components/CashPayment/CashPayment";
import pageInfo from "../pageInfo.json";

import styles from "./styles.module.scss";
import { getCanonicalUrlForInfoPage } from "utils/helpers/getCanonicalUrl";
import DolymePayment from "./Components/DolymePayment/DolymePayment";

const PaymentPage: React.FC = () => {

  const paymentData = pageInfo.payment;
  const canonicalUrl = getCanonicalUrlForInfoPage(window.location.href);

  return (
    <InfoPage metaData={paymentData} canonicalUrl={canonicalUrl}>
      <h1 className={styles.seo}>Инфо страница Оплата</h1>
      <SectionTitle title="Оплата" containerStyles={styles["custom-title"]}/>
      <div className={styles.root}>
        <CashPayment />
        <BankCardPayment />
        <DolymePayment />
        <LinkToDelivery />
      </div>
    </InfoPage>
  );
};

export default PaymentPage;