import { CSSTransition } from "react-transition-group";

import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomDropDownProps {
  isOpen: boolean;
  children: {
    buttonElement: React.ReactNode;
    dropdownElement: React.ReactNode;
  };
  containerStyles?: string;
  dropdownElementStyles?: string;
}

const CustomDropDown: React.FC<CustomDropDownProps> = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      {props.children.buttonElement}
      <CSSTransition
        in={props.isOpen}
        timeout={100}
        classNames={{
          enter: styles["dropdown-enter"],
          enterDone: styles["dropdown-enter-done"],
          exit: styles["dropdown-exit"],
          exitDone: styles["dropdown-exit-done"]
        }}
        mountOnEnter
      >
        <div className={cn(styles.dropdown, props.dropdownElementStyles)}>
          <div className={styles["dropdown-wrapper"]}>
            {props.children.dropdownElement}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CustomDropDown;
