// import { PaymentProvider, PrivatePaymentResponse } from "types/IGetOrderPaymentLink";
import { IPaymentsOptions } from "types/IPaymentOptions";
import { getUserToken } from "utils/localStorage";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";
import { AvailablePaymentMethodsResponse } from "types/IGetOrderPaymentLink";
import { fetchPrivate } from "utils/fetchPrivate";

export const getAvailablePaymentsMethods = async (
  options: IPaymentsOptions
): Promise<AvailablePaymentMethodsResponse | IErrorData> => {
  try {
    const token = getUserToken();
    const url = token
      ? `${process.env.REACT_APP_BACKEND_URL}/api/private/payments/available`
      : `${process.env.REACT_APP_BACKEND_URL}/api/public/payments/available`;

    const headers: Record<string, string> = {
      "Content-Type": "application/json"
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const fetchOptions: RequestInit = {
      method: "POST",
      headers,
      body: JSON.stringify(options)
    };

    const res = token
      ? await fetchPrivate(url, fetchOptions)
      : await fetch(url, fetchOptions);

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получение доступных способов оплаты",
        500
      );
    }

    const response: IGenericResponseRoot<AvailablePaymentMethodsResponse> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(response);
      throw new CustomError(errorData.message, errorData?.code);
    }

    if (response.response === null) {
      throw new CustomError("Не получилось получить доступные способы оплаты");
    }

    return response.response;
  } catch (error) {
    const errorData = getErrorData(error);
    // eslint-disable-next-line no-console
    console.error(
      "При получении доступных способов оплаты произошла ошибка:",
      errorData.message
    );
    return errorData;
  }
};
