import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowLeft from "../../assets/icons/svg/arrow-left.svg";
import ArrowRight from "../../assets/icons/svg/arrow.svg";
import CustomSelect from "components/CustomSelect/CustomSelect";
import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomDatePickerProps {
  locale: string;
  dayClassName: (date: Date) => string;
  selected: Date | null;
  onChange: (date: Date | null) => void;
  dateFormat: string;
  onChangeRaw: (
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  placeholderText: string;
  maxDate: Date;
  disabled: boolean;
  years: string[];
  months: string[];
  onClickOpenDateInput: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputValue: string;
  disabledDobSelect: boolean;
  customInputStyles?: string;
  "data-testid"?: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  locale,
  dayClassName,
  selected,
  onChange,
  dateFormat,
  onChangeRaw,
  placeholderText,
  maxDate,
  customInputStyles,
  disabled,
  years,
  months,
  onClickOpenDateInput,
  handleInputChange,
  handleKeyDown,
  handleBlur,
  inputValue,
  disabledDobSelect,
  "data-testid": dataTestId
}) => {
  return (
    <DatePicker
      locale={locale}
      dayClassName={dayClassName}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div className={styles.container}>
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className={styles["container__arrow-button"]}
            data-testid="prev-month-button"
          >
            <img
              src={ArrowLeft}
              alt="Предыдущий месяц"
              className={styles["container__icon"]}
            />
          </button>
          <CustomSelect
            options={years}
            value={date.getFullYear().toString()}
            onChange={(value) => changeYear(Number(value))}
            data-testid="year-select"
          />
          <CustomSelect
            options={months}
            value={months[date.getMonth()]}
            onChange={(value) => changeMonth(months.indexOf(value))}
            data-testid="month-select"
          />
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className={styles["container__arrow-button"]}
            data-testid="next-month-button"
          >
            <img
              src={ArrowRight}
              alt="Следующий месяц"
              className={styles["container__icon"]}
            />
          </button>
        </div>
      )}
      selected={selected}
      onChange={onChange}
      dateFormat={dateFormat}
      onChangeRaw={onChangeRaw}
      placeholderText={placeholderText}
      maxDate={maxDate}
      customInput={
        <input
          type="text"
          onClick={onClickOpenDateInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          maxLength={10}
          value={inputValue}
          className={cn(
            styles["dob-button"],
            {
              [styles["dob-button__title"]]: true,
              [styles["dob-button__title--selected"]]: !!selected,
              [styles["dob-button--disabled"]]: disabledDobSelect
            },
            customInputStyles
          )}
          disabled={disabledDobSelect}
          data-testid={dataTestId}
        />
      }
      disabled={disabled}
    />
  );
};

export default CustomDatePicker;
