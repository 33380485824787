import PropTypes from "prop-types";

import Slider from "react-slick";
import CustomArrowButton from "../CustomArrowButton";

import styles from "./styles.module.scss";
// TODO: согласовать слайдер под реализацию когда наступит время
// Не переводил компонент дабы не тянуть на проект не нужные в данный момент и возможно никогда не используемые типы react-slick
const CustomSlider = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.slider} ref={props.sliderWrapperRef}>
        {
          !!props.products.length &&
            <Slider
              ref={props.sliderRef}
              {...props.settings}
            >
              {props.children}
            </Slider>
        }
      </div>
      <div className={styles.pagination}>
        <CustomArrowButton duration='left' onClick={props.onClickSlickPrev} />
        <CustomArrowButton duration='right' onClick={props.onClickSlickNext} />
      </div>
    </div>
  );
};

const propTypes = {
  products: PropTypes.array.isRequired,
  sliderWrapperRef: PropTypes.object,
  sliderRef: PropTypes.object.isRequired,
  onClickSlickPrev: PropTypes.func.isRequired,
  onClickSlickNext: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    infinite: PropTypes.bool,
    dots: PropTypes.bool,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    lazyLoad: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    pauseOnHover: PropTypes.bool,
    arrows: PropTypes.bool,
  }),
};

CustomSlider.propTypes = propTypes;

export default CustomSlider;