import cn from "classnames";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";
import { IBanner } from "utils/constants/bestsellersConstants";

interface SelectiveBannerProps {
  banner: IBanner;
  idx: number;
}

const SelectiveBannerItem: React.FC<SelectiveBannerProps> = ({ banner, idx }) => {
  const bannerText = banner.text ? banner.text.split("\n") : [];
  return banner.link ? (
    <Link
      key={idx}
      to={banner.link}
      className={cn("keen-slider__slide", styles.slide)}
    >
      <SingleImageLoader
        src={banner.imageUrl}
        alt={`Banner ${idx + 1}`}
        className={styles["banner-image"]}
      />
      {bannerText.length > 0 && (
        <div className={styles["text-overlay"]}>
          <div className={styles["text-overlay__first-string"]}>
            {bannerText[0]}
          </div>
          {bannerText[1] && (
            <div className={styles["text-overlay__two-string"]}>
              {bannerText[1]}
            </div>
          )}
          <div className={styles["decorative-line"]}></div>
        </div>
      )}
    </Link>
  ) : (
    <div key={idx} className={cn("keen-slider__slide", styles.slide)}>
      <SingleImageLoader
        src={banner.imageUrl}
        alt={`Banner ${idx + 1}`}
        className={styles["banner-image"]}
      />
      {bannerText.length > 0 && (
        <div className={styles["text-overlay"]}>
          <div className={styles["text-overlay__first-string"]}>
            {bannerText[0]}
          </div>
          {bannerText[1] && (
            <div className={styles["text-overlay__two-string"]}>
              {bannerText[1]}
            </div>
          )}
          <div className={styles["decorative-line"]}></div>
        </div>
      )}
    </div>
  );
};

export default SelectiveBannerItem;
