import { SetStateAction, useState } from "react";

import CustomButton from "../../CustomButton/CustomButton";

import cn from "classnames";
import styles from "./styles.module.scss";

interface PromocodeInputProps {
  inputValue: string;
  inputClassname?: string;
  buttonClassname?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSendPromocode: () => Promise<void>;
  onRemovePromocode: () => Promise<void>;
  setIsNotValidCode: React.Dispatch<SetStateAction<string>>;
  notValidCodeMessage: string;
  isPromocodeSelected: boolean;
}

const PromocodeInput: React.FC<PromocodeInputProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onClickHandler = () => {
    props.onSendPromocode();
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
    props.setIsNotValidCode("");
  };

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
  };

  return (
    <div className={styles.root}>
      <input
        type="text"
        placeholder={isFocused ? "" : "Промокод"}
        value={props.inputValue}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        autoComplete="off"
        disabled={props.isPromocodeSelected}
        className={cn(styles.input, props.inputClassname, {
          [styles["input--not-valid"]]: !!props.notValidCodeMessage,
          [styles["input--success"]]: props.isPromocodeSelected
        })}
      />
      {props.isPromocodeSelected ? (
        <CustomButton
          title="Удалить"
          onClick={props.onRemovePromocode}
          className={cn(styles.button, props.buttonClassname)}
        />
      ) : (
        <CustomButton
          title="Применить"
          onClick={onClickHandler}
          className={cn(styles.button, props.buttonClassname)}
        />
      )}
      {props.isPromocodeSelected ? (
        <span className={styles.success}>Промокод применен успешно</span>
      ) : (
        !!props.notValidCodeMessage.length && (
          <span className={styles.error}>{props.notValidCodeMessage}</span>
        )
      )}
      {/* {props.notValidCodeMessage === "promocode:not_exist" &&
        <span className={styles.error}>
          Промокод не существует/недействителен
        </span>
      }
      {props.notValidCodeMessage === "promocode:inactive" &&
        <span className={styles.error}>
          Промокод не активный
        </span>
      }
      {props.notValidCodeMessage === "promocode:already_used" &&
        <span className={styles.error}>
          Промокод уже использован
        </span>
      }
      {props.notValidCodeMessage === "promocode:min_amount" &&
        <span className={styles.error}>
          Промокод не соответствует условиям
        </span>
      } */}
    </div>
  );
};

export default PromocodeInput;
