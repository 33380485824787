import { useEffect, useState } from "react";

import LandingHeaderBanner from "../components/LandingHeaderBanner/LandingHeaderBanner";
import LandingCategoryTabsBlock from "../components/LandingCategoryTabsBlock/LandingCategoryTabsBlock";
import LandingProfitBuyBlock from "../components/LandingProfitBuyBlock/BestsellersProfitBuyBlock";
import { IProductWithOffers } from "types/IProduct";
import LandingCategoryBlock, {
  ITab
} from "../components/LandingCategoryBlock/LandingCategoryBlock";
import {
  bestsellersBannersData,
  bestsellersQueryOptions,
  bodyCareInfo,
  bodyCareTabIds,
  bodyCareTabs,
  hairCareInfo,
  hairCareTabIds,
  hairCareTabs,
  linksProfitButton
} from "../../../utils/constants/bestsellersConstants";
import cosmeticImage from "../../../assets/bestseller/cosmetic-image.png";
import cosmeticImageLarge from "../../../assets/bestseller/close-up.png";
import bodyCareImageSmall from "../../../assets/bestseller/still-life-banner-s.png";
import bodyCareImageLarge from "../../../assets/bestseller/still-life-banner-l.png";
import LandingBodyBanner from "../components/LandingBodyBanner/LandingBodyBanner";
// import getProductsByArrayIds from "utils/helpers/getProductsByArrayIds";
import LandingLikeItBlock from "../components/LandingLikeItBlock/LandingLikeItBlock";
import getLandingsProducts from "utils/helpers/getLandingsProducts";
import { ICategoryTab } from "types/ICategoryTab";
import { CustomError, getErrorData } from "utils/getErrorData";
import useLandingsProducts from "utils/hooks/useLandingsProducts";
import HeadPageData from "utils/HeadPageData";
import { useLocation } from "react-router-dom";
import BannerItem from "../components/LandingHeaderBanner/components/BannerItem";

import styles from "./styles.module.scss";

const Bestsellers: React.FC = () => {
  const {
    products,
    isLoadingProducts,
    isFirstLoading,
    handleChangeShouldGetProductsStatus,
    isFirstLoad
  } = useLandingsProducts(bestsellersQueryOptions);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [profitProducts, setProfitProducts] = useState<IProductWithOffers[]>(
    []
  );
  const [hairCareTab, setHairCareTab] = useState<ITab[]>([
    { title: hairCareInfo[0].title, products: [], link: hairCareInfo[0].link },
    { title: hairCareInfo[1].title, products: [], link: hairCareInfo[1].link },
    { title: hairCareInfo[2].title, products: [], link: hairCareInfo[2].link }
  ]);

  const [bodyCareTab, setBodyCareTab] = useState<ITab[]>([
    { title: bodyCareInfo[0].title, products: [], link: bodyCareInfo[0].link },
    { title: bodyCareInfo[1].title, products: [], link: bodyCareInfo[1].link }
  ]);

  const location = useLocation();

  const fetchProductsForTab = async (tab: ICategoryTab) => {
    let products: IProductWithOffers[] = [];

    try {
      const { products: bestsellerProducts } = await getLandingsProducts(tab);
      products = bestsellerProducts;
    } catch (err) {
      const errorData = getErrorData(err);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return { title: tab.productType, products };
  };

  const fetchInitialTabsProducts = async () => {
    try {
      const hairCarePromises = hairCareTabs.map((tab) => fetchProductsForTab(tab));
      const bodyCarePromises = bodyCareTabs.map((tab) => fetchProductsForTab(tab));

      const hairCareResults = await Promise.allSettled(hairCarePromises);
      const bodyCareResults = await Promise.allSettled(bodyCarePromises);

      const successfulHairCareTabs = hairCareResults.reduce<ITab[]>((acc, result, index) => {
        if (result.status === "fulfilled" && result.value.products && result.value.products.length > 0) {
          acc.push({
            title: hairCareInfo[index].title,
            products: result.value.products,
            link: hairCareInfo[index].link
          });
        }
        return acc;
      }, []);

      const successfulBodyCareTabs = bodyCareResults.reduce<ITab[]>((acc, result, index) => {
        if (result.status === "fulfilled" && result.value.products && result.value.products.length > 0) {
          acc.push({
            title: bodyCareInfo[index].title,
            products: result.value.products,
            link: bodyCareInfo[index].link
          });
        }
        return acc;
      }, []);

      setHairCareTab(successfulHairCareTabs);
      setBodyCareTab(successfulBodyCareTabs);
    } catch (err) {
      const errorMessage = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(errorMessage.message);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialTabsProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingProducts && products.length > 0) {
      setProfitProducts(products);
    }
  }, [products, isLoadingProducts]);

  const firstSixProducts = profitProducts.slice(0, 6);
  const secondSixProducts = profitProducts.slice(6, 12);
  const thirdSixProducts = profitProducts.slice(12, 18);

  return (
    <div className={styles.root}>
      <HeadPageData
        title="Хиты продаж - Купить парфюмерию и косметику онлайн | Parfumart.ru"
        description="Xиты продаж в интернет-магазине Parfumart. Большой выбор оригинальной парфюмерии и профессиональной косметики c доставкой по всей России. Низкие цены, акции и скидки."
        canonical={`https://parfumart.ru${location.pathname}`}
      />
      <h1 className={styles.seo}>Хиты продаж от Parfumart</h1>
      <LandingHeaderBanner bannerItem={BannerItem} bannersData={bestsellersBannersData} />
      <LandingCategoryTabsBlock />
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={firstSixProducts}
        link={linksProfitButton.firstProfitBlock}
      />
      <LandingBodyBanner />
      <LandingCategoryBlock
        title="Уход за волосами"
        imageUrl={bodyCareImageSmall}
        imageUrlLargeDesktop={bodyCareImageLarge}
        imagePosition="left"
        tabs={hairCareTab}
        tabIds={hairCareTabIds}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={secondSixProducts}
        link={linksProfitButton.secondProfitBlock}
      />
      <hr className={styles.divider} />
      <LandingCategoryBlock
        title="Уход за телом"
        imageUrl={cosmeticImage}
        imageUrlLargeDesktop={cosmeticImageLarge}
        imagePosition="left"
        tabs={bodyCareTab}
        tabIds={bodyCareTabIds}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <LandingProfitBuyBlock
        isLoading={isLoadingProducts}
        isFirstLoading={isFirstLoading}
        products={thirdSixProducts}
        link={linksProfitButton.thirdProfitBlock}
      />
      <hr className={styles.divider} />
      <LandingLikeItBlock
        products={products}
        isLoadingProducts={isLoadingProducts}
        handleChangeShouldGetProductsStatus={
          handleChangeShouldGetProductsStatus
        }
        isFirstLoad={isFirstLoad}
      />
    </div>
  );
};

export default Bestsellers;
