import { Link } from "react-router-dom";

import breadcrumbsArrowIcon from "../../assets/icons/png/small-arrow.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import { SkeletonLine } from "../UI/Skeleton/SkeletonLine/SkeletonLine";
import { IBreadcrumb } from "utils/breadcrumbsUtils";

interface BreadcrumbsProps {
  breadcrumbs: IBreadcrumb[];
  containerStyles?: string;
  isLastItemArrowRotated?: boolean;
  showArrow?: boolean;
  isLoading?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <ul
      className={cn(styles.list, props.containerStyles)}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
    >

      {props.isLoading ? <SkeletonLine className={styles.skeleton} /> :
        props.breadcrumbs.map((item, idx) => {
          const isLastItem = idx === props.breadcrumbs.length - 1;

          return (
            <li
              key={idx}
              className={styles.list__item}
              itemScope
              itemProp="itemListElement"
              itemType="https://schema.org/ListItem"
            >
              {item.link ? (
                <>
                  <Link
                    itemProp="item"
                    to={item.link}
                    className={cn(styles.item__link, {
                      [styles["item__link--last"]]: isLastItem,
                    })}
                  >
                    <span itemProp="name">{item.value}</span>
                  </Link>
                  <meta itemProp="position" content={`${idx + 1}`} />
                </>
              ) : (
                <span
                  itemProp="item"
                  className={cn(styles.item__link, {
                    [styles["item__link--last"]]: isLastItem,
                  })}
                >
                  <span itemProp="name">{item.value}</span>
                  <meta itemProp="position" content={`${idx + 1}`} />
                </span>
              )}

              {(!!props.showArrow || !isLastItem) && (
                <img
                  src={breadcrumbsArrowIcon}
                  alt='breadcrumbs-arrow'
                  className={cn(styles.item__arrow, {
                    [styles["item__arrow--rotated"]]:
                      isLastItem && !!props.isLastItemArrowRotated,
                  })}
                />
              )}
            </li>
          );
        })
      }
    </ul>
  );
};

export default Breadcrumbs;
