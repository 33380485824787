import React from "react";

import questionMark from "assets/icons/svg/question-mark.svg";

import styles from "./styles.module.scss";

interface MarkdownButtonProps {
  onMarkdownAction: () => void;
}

export const MarkdownButton: React.FC<MarkdownButtonProps> = ({
  onMarkdownAction
}) => {
  return (
    <button className={styles["markdown-button"]} onClick={onMarkdownAction}>
      <img
        src={questionMark}
        alt="Уценка"
        className={styles["markdown-button__image"]}
      />
    </button>
  );
};
