import React from "react";

import { HeadHelmet } from "../../utils";

import SectionTitle from "../../components/SectionTitle/SectionTitle";

import styles from "./styles.module.scss";

interface IMetaData {
  title?: string;
  description?: string;
}

interface InfoPageProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  metaData?: IMetaData;
  canonicalUrl?: string;
}

const InfoPage: React.FC<InfoPageProps> = ({
  title,
  description,
  children = undefined,
  metaData = undefined,
  canonicalUrl = ""
}) => {
  return (
    <React.Fragment>
      <HeadHelmet
        title={metaData && metaData.title ? metaData.title : title}
        description={metaData && metaData.description ? metaData.description : description}
        canonical={canonicalUrl}
      />
      <div className={styles.root}>
        {title && (<SectionTitle title={title} />)}
        {children}
      </div>
    </React.Fragment>
  );
};

export default InfoPage;