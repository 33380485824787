import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";

import { removeUser } from "../../../../../../store/user/user";
import { clearValueFromStorage, removeTokens, setValueInStorage } from "../../../../../../utils/localStorage";

import personalAriaIcon from "../../../../../../assets/header_icons/personal_aria-icon.svg";
import ExitFromPersonalAreaContent from "../../../../../../components/ExitFromPersonalAreaContent/ExitFromPersonalAreaContent";
import PersonalAreaNavigationContent from "../../../../../../components/PersonalAreaNavigationContent/PersonalAreaNavigationContent";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";

import { getUserCart } from "store/user/cartThunks/cartThunks";
import cn from "classnames";
import styles from "./styles.module.scss";

interface PersonalAreaTabProps {
  buttonStyles?: string;
  iconStyles?: string;
  cb?: () => void;
  children?: React.ReactNode;
}

const PersonalAreaTab: React.FC<PersonalAreaTabProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowExitBS, setIsShowExitBS] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onCloseNavigationBottomsheet = () => {
    if (isShowExitBS) {
      return;
    }

    setIsOpen(false);
  };

  const onClickHandler = () => {
    if (!user?.phone) {
      const pathname = location.pathname;
      if (pathname === "/sign-up") {
        navigate("/sign-in", { replace: true });
        return;
      }
      navigate("/sign-up", { replace: true });
      return;
    }
    setIsOpen(true);
    props.cb?.();
  };

  const onCloseExitBottomsheet = ({ isExit }: { isExit: boolean }) => {
    if (isExit) {
      removeTokens();
      dispatch(removeUser());
      navigate("/", { replace: true });
      setIsOpen(false);
      setIsShowExitBS(false);
      clearValueFromStorage("user_city");
      setValueInStorage({ key: "isLoggedOut", value: "true" });
      dispatch(getUserCart({}));
      return;
    }
    setIsShowExitBS(false);
  };

  return (
    <li className={styles.root}>
      <button onClick={onClickHandler} className={cn(styles.tab__button, props.buttonStyles)}>
        <img
          src={personalAriaIcon}
          alt="personal_aria-icon"
          className={cn(styles["personal-aria-icon"], props.iconStyles)}
        />
        {props.children}
      </button>
      <BottomSheet
        isOpen={isOpen}
        onClose={onCloseNavigationBottomsheet}
        disableClickOutside={isShowExitBS}
      >
        <PersonalAreaNavigationContent
          onClose={onCloseNavigationBottomsheet}
          onContinueExit={() => setIsShowExitBS(true)}
        />
      </BottomSheet>
      <BottomSheet isOpen={isShowExitBS} onClose={() => setIsShowExitBS(false)} hideBackground>
        <ExitFromPersonalAreaContent onClose={onCloseExitBottomsheet} />
      </BottomSheet>
    </li>
  );
};

export default PersonalAreaTab;
