import cn from "classnames";
import styles from "./styles.module.scss";
import { SortTitle } from "../SortButton/SortButton";

interface SortInnerContentProps {
  currentTitle: string;
  titles: SortTitle[];
  onSelectTitle: (id: number) => () => Promise<void>;
  containerStyles?: string;
}

const SortInnerContent: React.FC<SortInnerContentProps> = (props) => {
  return (
    <div className={cn(styles.root, props.containerStyles)}>
      {props.titles.map((item) => {
        return (
          <button
            key={item.id}
            className={cn(styles.button, {
              [styles["button--selected"]]: item.name === props.currentTitle,
            })}
            onClick={props.onSelectTitle(item.id)}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

export default SortInnerContent;
