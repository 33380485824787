import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { productsApi } from "../../../api/productsApi";
import { getQueryStringForRequest } from "../../../utils/helpers/catalogPageHelper";
import useSearchParamsHandler from "../../../utils/hooks/useSearchParamsHandler";
import { useAppSelector } from "store/reduxHooks";
import { IProductWithOffers } from "types/IProduct";
import { isError } from "store/user/userThunks/userThunks";
import { getErrorData, REJECT_ERROR_CODE } from "utils/getErrorData";

const DEFAULT_OFFSET = "0";
const DEFAULT_SORTBY = "showed";
const DEFAULT_ORDERBY = "desc";

interface UseCatalogProductsReturn {
  products: IProductWithOffers[];
  productsAmount: number;
  isLoadingProducts: boolean;
  handleSetProducts: React.Dispatch<React.SetStateAction<IProductWithOffers[]>>;
  handleChangeProductsAmount: (amount: number) => void;
  handleChangeShouldGetProductsStatus: (value: boolean) => void;
}

const useCatalogProducts = (): UseCatalogProductsReturn => {
  const deviceType = useAppSelector((state) => state.user.deviceType);

  const maxProductsPerPage = deviceType.isMobile ? 10 : 18;

  const [products, setProducts] = React.useState<IProductWithOffers[]>([]);
  const [productsAmount, setProductsAmount] =
    React.useState(maxProductsPerPage);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState(false);
  const location = useLocation();
  const { searchParams } = useSearchParamsHandler();

  const currentCatalogSection = useMemo(() => {
    const currentMatch = location.pathname.match(/kosmetika|parfumeria/g);
    if (!currentMatch) {
      return "";
    }
    return currentMatch[0];
  }, [location.pathname]);

  const abortController = React.useRef<AbortController | null>(null);
  const shouldGetProducts = React.useRef(true);

  React.useEffect(() => {
    if (!shouldGetProducts.current) {
      return;
    }
    (async () => {
      try {
        abortController.current = new AbortController();
        setIsLoadingProducts(true);

        const params = Object.fromEntries(searchParams);

        const requestString = getQueryStringForRequest({
          ...params,
          limit: String(maxProductsPerPage),
          offset: params["offset"] ?? DEFAULT_OFFSET,
          // eslint-disable-next-line camelcase
          sort_by: params["sort_by"] ?? DEFAULT_SORTBY,
          // eslint-disable-next-line camelcase
          order_by: params["order_by"] ?? DEFAULT_ORDERBY,
          categories: currentCatalogSection
            ? currentCatalogSection
            : params["categories"]
              ? params["categories"]
              : undefined
        });

        const data = await productsApi.getCatalogWithSearchOptions({
          requestString,
          signal: abortController.current.signal
        });

        if (!data) {
          throw new Error("Не получилось получить товары");
        }

        if (isError(data)) {
          throw new Error(data.message);
        }

        setProducts(data.response.items);
        setProductsAmount(data.response.meta.total);
      } catch (err) {
        const errorData = getErrorData(err);
        if (errorData?.code === REJECT_ERROR_CODE) {
          // eslint-disable-next-line no-console
          console.error(errorData.message);
        } else {
          // eslint-disable-next-line no-console
          console.error(
            "Ошибка при получении товаров:",
            errorData.message
          );
        }
      } finally {
        setIsLoadingProducts(false);
      }
    })();

    return () => {
      abortController.current.abort();
    };
  }, [currentCatalogSection, maxProductsPerPage, searchParams]);

  const handleSetProducts = React.useCallback((products: IProductWithOffers[] | ((prev: IProductWithOffers[]) => IProductWithOffers[])) => {
    setProducts(products);
  }, []);

  const handleChangeProductsAmount = React.useCallback((amount: number) => {
    setProductsAmount(amount);
  }, []);

  const handleChangeShouldGetProductsStatus = React.useCallback((value: boolean) => {
    shouldGetProducts.current = value;
  }, []);

  return {
    products,
    productsAmount,
    isLoadingProducts,
    handleSetProducts,
    handleChangeProductsAmount,
    handleChangeShouldGetProductsStatus
  };
};

export default useCatalogProducts;
