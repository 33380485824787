import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { useAppDispatch } from "store/reduxHooks";
import { selectItemInCart } from "store/user/user";
import { ICartItem } from "types/ICartItem";
import { formatPrice } from "utils/helpers/formatedPrice";

import Checkbox from "components/UI/Checkbox/Checkbox";
import { CartProductPrice } from "../CartProductItemV2/components/CartProductPrice/CartProductPrice";
import { CartProductDescription } from "../CartProductItemV2/components/CartProductDescription/CartProductDescription";
import { CartProductActions } from "../CartProductItemV2/components/CartProductActions/CartProductActions";

import styles from "./styles.module.scss";

interface CartProductItemV2Props {
  product: ICartItem;
  setIsModal: (value: boolean | ((prevState: boolean) => boolean)) => void;
  setIdDelProduct: (
    id: number | null | ((prevState: number | null) => number | null)
  ) => void;
}

export const CartProductItemV2: React.FC<CartProductItemV2Props> = (props) => {
  const dispatch = useAppDispatch();

  const selectItem = useCallback(() => {
    dispatch(selectItemInCart(props.product?.offer?.id));
  }, [dispatch, props.product?.offer?.id]);

  const noImageLink = useMemo(() => {
    return props.product?.offer?.catalog?.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.offer?.catalog?.category?.rewrite_name]);

  const productImageLink = useMemo(() => {
    return props.product.offer.offerImage?.thumbnail_q_100?.link
      ? props.product.offer.offerImage.thumbnail_q_100.link
      : props.product.offer.offerImage?.original?.link
        ? props.product.offer.offerImage.original.link
        : props.product?.offer?.catalog?.productImage?.thumbnail_q_100?.link
          ? props.product.offer.catalog.productImage.thumbnail_q_100.link
          : props.product.offer.catalog.productImage?.original?.link
            ? props.product.offer.catalog.productImage.original.link
            : noImageLink;
  }, [noImageLink, props?.product]);

  const productUrl = useMemo(() => {
    let url = `/product/${props.product?.offer?.catalog.id}-${props.product?.offer?.catalog?.product_rewrite_name}`;
    if (props.product.offer.id) {
      url += `?type=${props.product.offer.id}`;
    }
    return url;
  }, [
    props.product?.offer?.catalog?.id,
    props.product?.offer?.catalog?.product_rewrite_name,
    props.product?.offer?.id
  ]);

  const minPrice = useMemo(() => {
    if (!props.product?.calculated_price) {
      const priceWithDiscount =
        props.product?.offer?.price_sale || props.product?.offer?.akcia;
      return priceWithDiscount || props.product?.offer?.price;
    }
    return props.product?.calculated_price;
  }, [
    props.product?.calculated_price,
    props.product?.offer?.akcia,
    props.product?.offer?.price,
    props.product?.offer?.price_sale
  ]);

  const displayPrice = minPrice || props.product?.offer?.price;
  const totalMainPrice = props.product?.offer?.price * props.product?.count;
  const totalDisplayPrice = displayPrice * props.product?.count;

  return (
    <div
      className={cn(styles["cart-product"], {
        [styles["cart-product--not-available"]]:
          !props.product?.offer?.available
      })}
    >
      {/* ОБЁРТКА КАРТИНКИ И ОПИСАНИЯ */}
      <div className={styles["cart-product__image-and-info-wrapper"]}>
        {/* КАРТИНКА + КНОПКА */}
        <div className={styles["cart-product__image-and-button-wrapper"]}>
          <Checkbox
            isSelected={props.product?.isSelected}
            setIsSelected={selectItem}
          />
          {/* TODO - add  */}
          <Link
            to={productUrl}
            className={styles["cart-product__image-wrapper"]}
          >
            <img
              src={productImageLink}
              alt={
                props.product?.offer?.catalog?.product_rewrite_name ??
                "Картинка товара"
              }
              className={cn(styles["cart-product__image"], {
                [styles["cart-product__image--not-available"]]:
                  !props.product?.offer?.available
              })}
            />
          </Link>
          <div className={styles["cart-product__empty-block"]} />
        </div>

        {/* ЦЕНА + ИНФОРМАЦИЯ О ТОВАРЕ */}
        <div className={styles["cart-product__info-wrapper"]}>
          <CartProductPrice
            totalPrice={`${formatPrice(totalMainPrice)} ₽`}
            salePrice={
              props.product?.offer?.price === minPrice
                ? null
                : `${formatPrice(totalDisplayPrice)} ₽`
            }
          />
          <CartProductDescription
            productLink={productUrl}
            productName={props.product?.offer?.catalog?.name ?? ""}
            productArticul={props.product?.offer?.catalog?.vendorCode ?? null}
            productPricePerPiece={displayPrice}
            productShortDescription={props.product?.offer?.name ?? null}
            productOfferGoodsTypes={
              props.product?.offer?.offerGoodsTypes ?? null
            }
          />
        </div>
      </div>

      {/* ДЕЙСТВИЯ С ТОВАРОМ */}
      <CartProductActions
        product={props.product}
        setIsModal={props.setIsModal}
        setIdDelProduct={props.setIdDelProduct}
      />
    </div>
  );
};
