/* eslint-disable no-console */
import { useEffect, useMemo, useRef, useState } from "react";

import getSortedOffersByPriceAndVolume from "../../../../../../utils/getSortedOffersByPriceAndVolume";
import { addProductToCart } from "../../../../../../store/user/cartThunks/cartThunks";
// import saleTicketIcon from "../../../../../../assets/icons/png/sale_ticket-icon.png";

import ProductOffersHeader from "../../../ProductOffersHeader/ProductOffersHeader";
import DolyamiDropDown from "../DropDowns/DolyamiDropDown/DolyamiDropDown";
import CostCalculationDropDown from "../DropDowns/CostCalculationDropDown/CostCalculationDropDown";
import HelpDropDown from "../DropDowns/HelpDropDown/HelpDropDown";
import OffersList from "./OffersList/OffersList";

import ModalFoundCheaper from "../../../../../../components/UI/ModalFoundCheaper/ModalFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";
import { metric } from "../../../../../../utils/yandexMetrics/yandexMetrics";
import { IProduct } from "types/IProduct";
import { IOffer } from "types/IOffer";
import { formatPrice } from "utils/helpers/formatedPrice";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";

import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { ICurrentOffer } from "types/ICurrentOffer";

export interface IProductOffersProps {
  offers: IOffer[];
  product: IProduct;
  isLoading: boolean;
  onChangeCurrentOffer: (offer: ICurrentOffer) => void;
  currentOffer: ICurrentOffer;
  onSelectOffer: (options: ICurrentOffer) => void;
}

export const ProductOffers: React.FC<IProductOffersProps> = (props) => {
  const { cart } = useAppSelector((state) => state.user);
  const [isModalCheaper, setIsModalCheaper] = useState(false);
  const dispatch = useAppDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);

  const sortedOffers = useMemo(() => {
    if (props.isLoading || !props.product) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
  }, [props.offers, props.isLoading, props.product]);

  const modalCheaperRef = useRef<HTMLDivElement | null>(null);

  const wordsArr = props.product?.name.split(" ");
  const fiveWords = wordsArr?.splice(0, 5);
  const text =
    fiveWords?.length <= wordsArr?.length
      ? fiveWords?.join(" ")
      : fiveWords?.join(" ") + "...";

  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id: number) => {
    const selectedOffer = sortedOffers.find((offer) => offer.id === id);

    if (!selectedOffer) {
      return;
    }

    const productVariantString = `${selectedOffer.name}, ID: ${selectedOffer.id}`;
    const ecommerceAddData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.product.id?.toString(),
              name: props.product.name,
              category: props.product.category.name,
              brand:
                props.product.brands && !!props.product.brands.length
                  ? props.product.brands[0].brand.name
                  : "",
              price:
                props.currentOffer.promotionPrice ?? props.currentOffer.price,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const addProduct = async () => {
    if (
      !props.product ||
      (!props.currentOffer.promotionPrice && !props.currentOffer.price)
    ) {
      return;
    }

    try {
      if (!props.currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }
      const options = {
        id: props.currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(props.currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };
  const productInCart = useMemo(
    () => cart.find((e) => e.offer.id === props.currentOffer.id),
    [cart, props.currentOffer]
  );

  const addProductAmount = async () => {
    try {
      if (!props.currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }
      const options = {
        id: props.currentOffer.id,
        count: productInCart.count + 1
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(props.currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  useEffect(() => {
    if (props.isLoading) return;
    const filteredOffered = sortedOffers.filter(
      (offer) => offer.id === Number(type)
    );

    if (filteredOffered[0]) {
      return props.onChangeCurrentOffer({
        id: filteredOffered[0]?.id || null,
        description: filteredOffered[0]?.name ?? "",
        promotionPrice:
          filteredOffered[0]?.price_sale ?? filteredOffered[0]?.akcia ?? null,
        price: filteredOffered[0]?.price ?? null,
        percent: filteredOffered[0]?.percent ?? null,
        available: sortedOffers[0]?.available ?? null
      });
    }

    props.onChangeCurrentOffer({
      id: sortedOffers[0]?.id || null,
      description: sortedOffers[0]?.name ?? "",
      promotionPrice:
        sortedOffers[0]?.price_sale ?? sortedOffers[0]?.akcia ?? null,
      price: sortedOffers[0]?.price ?? null,
      percent: sortedOffers[0]?.percent ?? null,
      available: sortedOffers[0]?.available ?? null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers, type]);

  return (
    <div className={styles.root}>
      <ProductOffersHeader
        brandRewriteName={props.product?.brands[0].brand.rewrite_name}
        brandName={props.product?.brands[0].brand.name}
        productName={props.product?.name}
        currentOffer={props.currentOffer}
        isLoading={props.isLoading}
      />
      {!!sortedOffers.length && (
        <span className={styles["product_select-title"]}>
          Выберите вариант товара
        </span>
      )}
      <OffersList
        productId={props.product?.id}
        sortedOffers={sortedOffers}
        activeOfferId={props.currentOffer.id}
        onSelectOffer={props.onSelectOffer}
        product={props.product}
      />
      <div className={styles["product__buttons"]}>
        {/* Функционал скрыт в рамках задачи PAR-2415 из-за предновогодней загрузки менеджеров */}
        {/* {!!sortedOffers.length && (
          <button className={styles["product__buttons__cheap"]} onClick={() => setIsModalCheaper(true)}>
            <img
              src={saleTicketIcon}
              alt="sale_ticket-icon"
              className={styles["product__buttons__cheap_icon"]}
            />
            <span className={styles["product__buttons__cheap_title"]}>
              Нашли дешевле? Сообщите нам!
            </span>
          </button>
        )} */}
        {!props.currentOffer.available && (
          <span className={styles["product__available"]}>
            Товара нет в наличии
          </span>
        )}
        <button
          className={cn(styles["product__buttons__cart"], {
            [styles["product__buttons__cart-disabled"]]:
              props.isLoading || !props.currentOffer.available
          })}
          onClick={!productInCart ? addProduct : addProductAmount}
          disabled={props.isLoading || !props.currentOffer.available}
        >
          {productInCart ? (
            <span className={styles["product__buttons__cart--title"]}>
              Добавить ещё
            </span>
          ) : (
            <span className={styles["product__buttons__cart--title"]}>
              Добавить в корзину
            </span>
          )}
          <span className={styles["product__buttons__cart_price"]}>
            {!props.currentOffer.promotionPrice ? (
              !!props.currentOffer.price && `${props.currentOffer.price} ₽`
            ) : (
              <>
                <span className={styles["product__buttons__cart_price--promo"]}>
                  {`${formatPrice(props.currentOffer.promotionPrice)} ₽ `}
                </span>
                <span className={styles["product__buttons__cart_price--main"]}>
                  {`${formatPrice(props.currentOffer.price)} ₽ `}
                </span>
              </>
            )}
          </span>
        </button>
      </div>
      {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN === "true" && (
        <DolyamiDropDown
          price={props.currentOffer.promotionPrice ?? props.currentOffer.price}
        />
      )}
      {!!sortedOffers.length && (
        <CostCalculationDropDown
          price={props.currentOffer.promotionPrice ?? props.currentOffer.price}
          isLoading={props.isLoading}
        />
      )}
      <HelpDropDown isLoading={props.isLoading} />
      {!props.isLoading && isModalCheaper && (
        <ModalFoundCheaper
          currentOfferId={props.currentOffer.id}
          onClose={() => setIsModalCheaper(false)}
          modalRef={modalCheaperRef}
        />
      )}
    </div>
  );
};

export default ProductOffers;
