import { FC } from "react";
import styles from "./styles.module.scss";
import infoCircle from "../../assets/icons/dangerCircle.svg";

interface PasswordHintProps {
    message: string
}


const PasswordHint: FC<PasswordHintProps> = ({ message }) => {
  return (
    <div className={styles["password-hint"]} data-testid="password-hint-signup">
      <img className={styles["password-hint__icon"]} src={infoCircle} alt="info-circle" />
      <p className={styles["password-hint__message"]}>{message}</p>
    </div>
  );
};

export default PasswordHint;